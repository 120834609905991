{
  "bank_account": "Cuenta bancaria",
  "cancel": "Cancelar",
  "crypto_wallet": "Cripto Monedero",
  "exchange": "Intercambio",
  "order_has_been_canceled": "El pedido ha sido cancelado",
  "order_has_been_proceeded": "El pedido ha sido procesado",
  "order_has_been_suspended": "El pedido ha sido suspendido",
  "order_status_history": "Historial de estados",
  "orders": "Pedidos",
  "payment_information": "Informaci\u00f3n del destinatario del pago",
  "proceed": "Proceder",
  "rates": "Tasas",
  "return": "Devoluci\u00f3n",
  "setting_language_preference_text": "Escoja el idioma de su preferencia para mostrar el sitio web y recibir correos electr\u00f3nicos.",
  "setting_send_email_after_login": "Enviar correo electr\u00f3nico despu\u00e9s de cada inicio de sesi\u00f3n.",
  "suspend": "Suspender",
  "wallet_updated_successfully": "Monedero actualizado con \u00e9xito.",
  "we_will_send_you_money_back_shortly": "Le mandaremos el dinero en breve.",
  "guest_login_and_signup_order_separation": "o",
  "order_button_create_order": "Crear pedido",
  "guest_login": "Iniciar sesi\u00f3n",
  "guest_signup": "Registrarse",
  "menu_guest_link_about_us": "Acerca de nosotros",
  "menu_guest_link_help": "Ayuda",
  "menu_guest_link_terms": "T\u00e9rminos de servicio",
  "order_form_caption": "Cree su pedido aqu\u00ed",
  "order_form_email": "Correo electr\u00f3nico",
  "forgot_password": "\u00bfOlvid\u00f3 su contrase\u00f1a?",
  "login_form_submit_button": "Iniciar sesi\u00f3n",
  "page_login_title": "Iniciar sesi\u00f3n en {{brandName}}",
  "page_signup_title": "Registrarse",
  "signup_form_please_fill": "Por favor, complete los siguientes campos para registrarse:",
  "signup_form_submit_button": "Registrarse",
  "order_form_placeholder_currency": "Moneda",
  "refund_widget_add_new_refund_address": "Agregar nueva billetera",
  "refund_address_select_label": "Direcci\u00f3n de reembolso",
  "copied": "Copiado",
  "suspended_widget_return_money_back": "Devolver dinero",
  "suspended_widget_top_alert_message": "La orden est\u00e1 suspendida, esperando su decisi\u00f3n.",
  "add_order": "Crear nueva orden",
  "language": "Idioma",
  "logged_in": "Inicio de sesi\u00f3n",
  "search": "Buscar",
  "status_direction": "Destino del estado",
  "status_direction_description_cancelled": "Cancelar pedido",
  "status_direction_description_proceed": "Proceder con pedido",
  "status_direction_description_return_to_client": "Devolver mi dinero/monedas",
  "status_direction_description_suspended": "Suspender pedido",
  "status_final": "Estado final",
  "status_name_cancelled": "Cancelado",
  "status_name_confirming_incoming": "Confirmaci\u00f3n entrante",
  "status_name_confirming_outgoing": "Confirmaci\u00f3n saliente",
  "status_name_delivered": "Entregado",
  "status_name_done": "Completo",
  "status_name_new": "Nuevo",
  "status_name_proceed": "Proceder",
  "status_name_received": "Recibido",
  "status_name_return_to_client": "Devoluci\u00f3n al cliente",
  "status_name_returned": "Devuelto",
  "status_name_suspended": "Suspendido",
  "suspended_widget_table_change_percent": "Cambiar",
  "client_bank_account_list_add_new_account": "Agregar cuenta nueva",
  "account_form_create_button": "Agregar cuenta bancaria",
  "account_form_save_button": "Guardar",
  "create_new_wallet_header": "Agregar un monedero nuevo",
  "delete_account": "Eliminar cuenta",
  "delete_wallet": "Eliminar monedero",
  "update_account": "Actualizar cuenta",
  "update_wallet": "Actualizar el monedero",
  "wallet_form_create_button": "Agregar monedero",
  "header_what_now": "Siguiente paso:",
  "profile_page_header_my_documents": "Archivos adjuntos",
  "profile_page_header_my_profile": "Mi perfil",
  "profile_page_ready_for_verification_we_are_checking_your_profile": "Su perfil est\u00e1 siendo revisado.",
  "profile_page_save_button": "Guardar",
  "profile_page_tab_level1": "Nivel 1",
  "profile_page_tab_level2": "Nivel 2",
  "profile_page_tab_this_level_is_accepted": "Este nivel ha sido aceptado",
  "refund_address_set_successfully": "La direcci\u00f3n de reembolso se ingres\u00f3 correctamente",
  "profile_level_checkbox_ready_for_verification_you_would_not_be_able_to_modify_your_information_it_sent_to_admin": "Si hace clic en la casilla y env\u00eda el formulario, no podr\u00e1 modificar ni eliminar sus archivos. Los datos se enviar\u00e1n a nuestros moderadores para una revisi\u00f3n.",
  "client_sidebar_avatar_hash_description": "Recuerde este avatar. Ha sido generado exclusivamente para su cuenta. Si ha cambiado, es posible que alguien haya estado en nuestra p\u00e1gina. Verifique que en la barra de direcciones de su navegador la direcci\u00f3n sea correcta y que muestre el candado verde a la izquierda.",
  "navigation_link_logout": "Cerrar sesi\u00f3n",
  "navigation_link_rates": "Tasas",
  "company_about": "Simplecoin es una compa\u00f1ia europea de intercambio de criptomonedas con sede en el coraz\u00f3n de Europa, Praga, Rep\u00fablica Checa. Nuestro objetivo principal es hacer que el uso del bitcoin y otras criptomonedas sea muy f\u00e1cil. Con Simplecoin puedes comprar, vender o enviar bitcoins y otras monedas de forma segura y simple.",
  "sidebar_navigation_orders": "Pedidos",
  "sidebar_navigation_profile": "Perfil",
  "sidebar_navigation_security": "Seguridad",
  "sidebar_navigation_settings": "Ajustes",
  "company_header": "Acerca de {{brandName}}",
  "company_header_contacts": "Contactos",
  "company_header_social": "Social",
  "company_subheader": "INTERCAMBIO SIMPLE DE CRIPTOMONEDAS",
  "company_social_group": "Grupo {{brandName}}",
  "company_header_billing_address": "Direcci\u00f3n de facturaci\u00f3n",
  "page_header_company": "{{brandName}} Bitcoin &  Intercambio de criptomonedas | BTC LTC ETH BCH XRP",
  "footer_sidebar_link_about": "Acerca de",
  "client_order_reset_all_label": "Resetear todo:",
  "client_order_search_btn": "Buscar pedidos",
  "transaction_accepted_by_banks_now_waiting_for_transaction_being_listed_on_bank_record": "La transacci\u00f3n fue aceptada por el banco, ahora estamos esperando que la transacci\u00f3n aparezca en la lista de transacciones bancarias salientes.",
  "error": "Error",
  "client_bank_accounts_widget_header": "Documentos de la cuenta bancaria",
  "new_return_to_client_waiting_for_transaction_to_be_matched_and_return_your_funds": "Estamos esperando que la transacci\u00f3n coincida. La enviaremos de vuelta cuando llegue porque la direcci\u00f3n del pedido es \"devolver al cliente\".",
  "page_subheader_settings_order_emails": "Correos electr\u00f3nicos para pedidos",
  "page_subheader_settings_security_emails": "Correos electr\u00f3nicos de seguridad",
  "client_setting_hint_send_email_when_order_confirming_incoming": "Enviarme un correo electr\u00f3nico cuando el pedido haya sido emparejado con una transacci\u00f3n criptogr\u00e1fica entrante esperando a ser confirmada",
  "client_setting_hint_send_email_when_order_confirming_outgoing": "Enviarme correo electr\u00f3nico cuando la transacci\u00f3n bancaria o cripto est\u00e9 en camino",
  "client_setting_hint_send_email_when_order_done": "Enviarme un correo electr\u00f3nico cuando el estado del pedido haya cambiado a completo.",
  "client_setting_hint_send_email_when_order_proceed": "Enviarme un correo electr\u00f3nico cuando el estado del pedido haya cambiado para proceder.",
  "client_setting_hint_send_email_when_order_received": "Enviarme un correo electr\u00f3nico cuando SimpleCoin haya recibido el pago bancario del pedido o cuando el pago entrante est\u00e9 confirmado",
  "client_setting_hint_send_email_when_order_return_to_client": "Enviarme un correo electr\u00f3nico cuando el estado del pedido haya cambiado a \"devuelto\".",
  "client_setting_hint_send_email_when_order_suspended": "Enviarme un correo electr\u00f3nico cuando el estado del pedido haya cambiado a suspendido.",
  "client_settings_label_order_confirming_incoming": "Confirmaci\u00f3n entrante",
  "client_settings_label_order_confirming_outgoing": "Confirmaci\u00f3n saliente",
  "client_settings_label_order_done": "Completo",
  "client_settings_label_order_proceed": "Proceder",
  "client_settings_label_order_received": "Recibido",
  "client_settings_label_order_return_to_client": "Devoluci\u00f3n al cliente",
  "client_settings_label_order_suspended": "Suspendido",
  "page_subheader_settings_general": "General",
  "profile_page_level1_my_document_subheader_paragraph": "Cualquier otro documento que pueda ser usado para su identificaci\u00f3n en l\u00ednea. Puede adjuntar varios archivos aqu\u00ed.",
  "profile_page_level2_my_document_subheader_paragraph": "Puede subir varios archivos aqu\u00ed",
  "profile_page_level2_note_description": "Si se registra como una compa\u00f1\u00eda, declare cu\u00e1l es su posici\u00f3n, el nombre de la compa\u00f1\u00eda y el n\u00famero de registro. Explicar, si hay situaciones no est\u00e1ndares como recibir pagos de terceros, hacer pedidos para alguien m\u00e1s, etc\u00e9tera.",
  "profile_page_level2_trading_purpose_description": "Por favor, declare cu\u00e1l es el prop\u00f3sito de su intercambio. Los reguladores checos nos obligan a preguntar esta informaci\u00f3n.",
  "profile_page_level2_trading_source_of_income_description": "Por favor, describa c\u00f3mo obtuvo los fondos para las transacciones. Para cantidades muy grandes, le pediremos cualquier tipo de documento que pueda respaldar su estado de cuenta. Si no puede subir ninguno, escriba expl\u00edcitamente que no existe tal evidencia",
  "action_reset_password_error_email_not_sent": "Lo sentimos, no se pudo restablecer la contrase\u00f1a para el correo electr\u00f3nico ingresado.",
  "action_reset_password_success": "Revise su correo electr\u00f3nico para m\u00e1s instrucciones.",
  "address_was_deleted": "La direcci\u00f3n ha sido eliminada.",
  "bank_account_was_added": "La cuenta bancaria ha sido ingresada.",
  "bank_account_was_deleted": "La cuenta bancaria ha sido eliminada.",
  "bank_account_was_updated": "La cuenta bancaria fue actualizada.",
  "change": "Cambiar",
  "change_password": "Cambiar contrase\u00f1a",
  "delete": "Eliminar",
  "email_confirmed": "Su correo electr\u00f3nico ha sido confirmado.",
  "enter_email_address_to_recover_account": "Por favor ingrese su correo electr\u00f3nico. Le enviaremos un enlace para restablecer tu contrase\u00f1a.",
  "file_was_deleted": "Archivo eliminado",
  "information_successfully_saved": "Su informaci\u00f3n ha sido guardada correctamente.",
  "new_address_was_added": "La nueva direcci\u00f3n ha sido a\u00f1adida.",
  "new_password_saved": "La nueva contrase\u00f1a ha sido guardada.",
  "order_has_been_created_guest": "La orden ha sido creada. Por favor, revise su correo electr\u00f3nico para m\u00e1s informaci\u00f3n acerca de este pedido.",
  "pls_choose_new_password": "Por favor, escoja su nueva contrase\u00f1a:",
  "request_password_reset": "Requerir restablecer contrase\u00f1a",
  "reset_password": "Restablecer contrase\u00f1a",
  "save": "Guardar",
  "security": "Seguridad",
  "send": "Enviar",
  "signup": "Registrarse",
  "update": "Actualizar",
  "are_you_sure_you_want_to_delete_this_file": "\u00bfEst\u00e1 seguro que quiere eliminar este archivo?",
  "client_bank_account_message_warning_about_symbol": "* Se agregar\u00e1 el s\u00edmbolo al mensaje cuando le enviemos el dinero.",
  "from_amount_currency_label": "Del monto",
  "to_amount_currency_label": "Al monto",
  "menu_guest_link_rates": "Tasas",
  "is_automatic_proceed_on_duplicate_payment": "Establecer estado \"Continuar\" en pedidos duplicados autom\u00e1ticamente",
  "is_automatic_proceed_on_late_payment": "Continuar Autom\u00e1ticamente con el Pago Atrasado",
  "setting_hint_is_automatic_proceed_on_duplicate_payment": "Cuando llega un nuevo pago duplicado (el pago coincide con el pedido que ya se pag\u00f3), el sistema copiar\u00e1 autom\u00e1ticamente el pedido pagado. Normalmente, esa orden copiada se suspende y esperar\u00e1 su decisi\u00f3n. Si esta casilla est\u00e1 marcada, el pedido copiado se actualizar\u00e1 al precio actual y el estado establecido en \"continuar\". De esta manera, puede intercambiar monedas simplemente enviando pagos. No se necesitar\u00e1 interacci\u00f3n con nuestra p\u00e1gina web.",
  "setting_hint_is_automatic_proceed_on_late_payment": "Cuando llegue el pago atrasado, el sistema aceptar\u00e1 autom\u00e1ticamente el nuevo precio de mercado y proceder\u00e1 con el pedido. Por defecto, el pedido se suspender\u00e1 y esperar\u00e1 su decisi\u00f3n.",
  "no_camera_found": "No se encontr\u00f3 la c\u00e1mara o no se proporcion\u00f3 acceso.",
  "scan_address": "Escanear QR",
  "button_disable": "Inhabilitar",
  "confirm": "Confirmar",
  "header_disable_google_authenticator": "Inhabilitar doble factor de autenticaci\u00f3n ",
  "header_enable_google_authenticator": "Habilitar doble factor de autenticaci\u00f3n ",
  "label_2fa_password": "Contrase\u00f1a",
  "label_2fa_secret": "Secreto",
  "2fa_secret_description": "Nota: realizar una copia de seguridad de este c\u00f3digo y no lo comparta. Puede ser usado para recuperar acceso al doble factor de autenticaci\u00f3n, en caso pierda su tel\u00e9fono.",
  "btn_disable": "Inhabilitar",
  "btn_enable": "Habilitar",
  "two_factor_authentication": "Doble factor de autenticaci\u00f3n ",
  "fill_information_below_to_disable_two_factor_authentication": "Complete la informaci\u00f3n a continuaci\u00f3n para deshabilitar el doble factor de autenticaci\u00f3n:",
  "alert_success_email_link_was_resent_again": "La nueva activaci\u00f3n por correo electr\u00f3nico se envi\u00f3 con \u00e9xito.",
  "hint_login_form_2fa_code": "No se requiere c\u00f3digo cuando el doble factor de autenticaci\u00f3n no est\u00e1 habilitado en la cuenta.",
  "footer_sidebar_link_terms": "T\u00e9rminos del servicio",
  "button_download_order_pdf": "Descargar PDF",
  "button_hint_download_order_pdf": "Descargue la informaci\u00f3n del pedido en PDF",
  "alert_success_affiliate_payout_request_successfully_created": "Solicitud de retiro de afiliados recibida con \u00e9xito.",
  "alert_success_you_have_become_affiliate": "Se ha convertido con \u00e9xito en nuestro afiliado.",
  "button_create": "Crear",
  "button_save": "Guardar",
  "page_affiliate_dashboard_copy_url_header": "URL del afiliado",
  "page_affiliate_dashboard_overview_header": "Resumen de afiliados",
  "page_affiliate_dashboard_overview_paragraph": "A continuaci\u00f3n puede ver un resumen de su cuenta de afiliado.",
  "page_affiliate_dashboard_paragraph": "En esta p\u00e1gina puede ver un resumen general de su cuenta de afiliado, ver estad\u00edsticas detalladas y solicitar el retiro en cualquier moneda que {{brandName}} maneje.",
  "page_affiliate_statistics_button": "Estad\u00edsticas",
  "page_affiliate_stats_first_paragraph": "En esta p\u00e1gina puede ver las estad\u00edsticas de su programa de afiliados. Cu\u00e1ntas veces fue utilizado por los usuarios finales y cu\u00e1ndo.",
  "page_affiliate_stats_label_conversion": "Clics de Banner",
  "page_affiliate_stats_label_period": "Fecha",
  "page_affiliate_withdraw_button": "Retirar dinero",
  "page_become_affiliate_first_paragraph": "Si desea convertirse en nuestro afiliado, marque la casilla a continuaci\u00f3n para confirmar que est\u00e1 de acuerdo con nuestras condiciones de afiliado y env\u00ede el formulario para continuar.",
  "page_header_affiliate_dashboard": "Panel del afiliado",
  "page_header_become_affiliate": "Convi\u00e9rtete en afiliado",
  "sidebar_navigation_affiliate": "Afiliado",
  "label_agree_with_affiliate_conditions": "Acepto las condiciones de afiliado",
  "page_affiliate_withdraw_request_paragraph": "Elija una cuenta bancaria o una direcci\u00f3n para retirar sus fondos de su balance de afiliado.",
  "page_affiliate_finance_first_paragraph": "En esta p\u00e1gina, ver\u00e1 la lista de los pedidos asociados con su cuenta y las ganancias que recibi\u00f3 por cada uno en EUR.",
  "page_affiliate_finance_label_order": "Pedido",
  "page_affiliate_finance_button": "Ingreso",
  "page_affiliate_finance_label_profit_in_eur": "Ganancia (EUR)",
  "page_affiliate_asset_banners_paragraph": "Incluya banners interactivos (abajo) en su sitio web. Copie cualquiera de los c\u00f3digos a continuaci\u00f3n e ins\u00e9rtelo en el lugar donde desea que se muestre.",
  "page_affiliate_assets_button": "Materiales de promoci\u00f3n",
  "api_order_unsupported_currency_pair": "El intercambio no est\u00e1 disponible para este par de monedas",
  "footer_sidebar_link_data_processing": "GDPR",
  "alert_success_ticket_created": "Ticket creado con \u00e9xito.",
  "alert_success_ticket_reply_sent": "Respuesta al ticket enviado con \u00e9xito.",
  "button_send": "Enviar",
  "page_header_ticket_create": "Nuevo Ticket",
  "page_header_ticket_list": "Tickets",
  "page_header_ticket_reply": "Responder Ticket",
  "page_ticket_reply_you": "Usted",
  "sidebar_navigation_ticket": "Soporte",
  "alert_success_ticket_closed": "Ticket marcado como resuelto.",
  "button_create_support_ticket": "Soporte",
  "button_hint_create_support_ticket": "Puede crear un ticket de soporte si tiene alguna pregunta o problema con este pedido.",
  "page_tickets_pending_tickets": "Tickets pendientes",
  "page_tickets_solved_tickets": "Tickets resueltos",
  "page_tickets_no_pending_tickets": "A\u00fan no hay tickets creados o pendientes.",
  "page_tickets_no_solved_tickets": "A\u00fan no hay tickets resueltos.",
  "take_selfie_button": "Tomar selfie",
  "no_camera_access_provided": "No hay acceso a la c\u00e1mara. Verifique los permisos de la c\u00e1mara y aseg\u00farese de que no est\u00e9n deshabilitados.",
  "menu_guest_link_privacy_policy": "Pol\u00edtica de privacidad",
  "change_password_social_reset_password_claim": "Use el bot\u00f3n de abajo para cambiar su contrase\u00f1a. Le enviaremos un correo electr\u00f3nico con un enlace para establecer una nueva contrase\u00f1a.",
  "alert_change_password_email_was_sent": "Verifique su correo electr\u00f3nico donde puede ver el enlace para configurar su contrase\u00f1a.",
  "page_confirm_two_factor": "Confirmar doble factor de autenticaci\u00f3n",
  "page_confirm_two_factor_paragraph": "Ingrese el c\u00f3digo de su aplicaci\u00f3n de autenticaci\u00f3n de dos factores:",
  "cookie_consent_i_agree": "Entiendo",
  "cookie_consent_message": "Este sitio utiliza cookies para ayudar con la navegaci\u00f3n y capacidad para proporcionar feedback, analizar su uso de nuestros productos y servicios",
  "try_again_button": "Intente otra vez",
  "dropable_or_pastable_from_clipboard": "Arrastre y suelte aqu\u00ed los archivos desde su PC o pegue aqu\u00ed una imagen en el portapapeles presionando CTRL-V",
  "hint_enter_two_factor_code_to_confirm_action": "Ingrese c\u00f3digo de  Google Authenticator.",
  "add_new_ticket": "Agregar nuevo ticket",
  "close_ticket": "Cerrar",
  "client_setting_hint_send_email_limit_price_changed": "Enviarme un correo electr\u00f3nico cuando el precio l\u00edmite de la orden ha sido establecido o modificado.",
  "client_settings_label_order_limit_price_changed": "Precio L\u00edmite",
  "button_set_limit": "Establecer l\u00edmite",
  "suspended_widget_header_limit_rate": "Tasa l\u00edmite",
  "suspended_widget_header_limit_rate_hint": "Al establecer la tasa l\u00edmite, su orden ser\u00e1 aceptada autom\u00e1ticamente cuando la tasa de mercado alcance la tasa l\u00edmite establecida por usted.",
  "suspended_widget_header_market_rate": "Tasa de mercado",
  "suspended_widget_header_market_rate_hint": "La tasa de mercado que podemos ofrecerle por su pedido. Todas las tarifas est\u00e1n incluidas, por lo que esta es la tasa exacta que obtendr\u00e1.",
  "suspended_widget_header_order_rate": "Tasa del pedido",
  "suspended_widget_header_order_rate_hint": "Esta es la tasa que establecimos para usted en el momento en que cre\u00f3 el nuevo pedido. Incluye todas las tarifas.",
  "button_change_limit": "Cambiar",
  "button_limit_cancel": "Cancelar",
  "suspended_widget_limit_form_paragraph": "Estos dos campos representan la misma tasa. El valor del segundo campo contiene el valor inverso del primero y se actualizan en consecuencia. Le permite ingresar la tasa de la forma en que est\u00e1 acostumbrado.",
  "hint_close_ticket_button": "Si hace clic en este bot\u00f3n, el ticket se marcar\u00e1 como resuelto y no se podr\u00e1 realizar m\u00e1s discusiones a menos que cree un nuevo ticket.",
  "heading_chart": "Gr\u00e1fico",
  "button_close": "Cerrar",
  "boolean_option_no": "No",
  "boolean_option_yes": "S\u00ed",
  "generic_link_learn_more": "Aprenda m\u00e1s",
  "profile_page_header_my_documents_level0_paragraph": "<strong>Adjunta los siguientes documentos personales (dos documentos son suficientes):</strong><br>\n-se requiere documento de identidad (ambos lados) o pasaporte como documento de identificaci\u00f3n principal, ambos est\u00e1n bien<br>\n-licencia de conducir o alg\u00fan otro documento con foto (tambi\u00e9n en ambos lados) <br><br>\n-probar la existencia de la cuenta bancaria: encabezado del estado de cuenta con un nombre y n\u00famero de cuenta, cree su cuenta en la secci\u00f3n \"Cuentas\" y agregue el documento all\u00ed mismo <br><br> Necesitamos escaneos de buena calidad (300 ppp) o fotos que sean completamente legibles, no se puede editar ni eliminar ninguna parte de ning\u00fan documento",
  "profile_page_header_my_documents_level1_paragraph": "<strong>Adjunte los siguientes documentos:</strong><br>\n- cualquier documento que pueda ayudar a probar su fuente de ingreso<br>\n- foto de usted sosteniendo su identificaci\u00f3n<br>\n- documentos de registro de la empresa, si aplica<br>\n- cualquier otro documento que solicite nuestro personal",
  "chart_module_market_price_inverse": "Inverso",
  "chart_module_no_data_available": "No hay datos disponibles.",
  "bank_account_verified": "Verificado",
  "profile_page_bank_account_header": "Sus cuentas bancarias",
  "profile_page_bank_accounts_paragraph": "Para una identificaci\u00f3n exitosa por internet, debemos verificar al menos una cuenta bancaria. En la p\u00e1gina de recipientes, configure su cuenta, luego adjunte el documento requerido seg\u00fan las instrucciones y, alternativamente, env\u00ede pruebas de pago.",
  "bank_account_file_upload_unverified_level0_paragraph": "Para finalizar su identificaci\u00f3n por internet (verificaci\u00f3n de perfil de Nivel 1) necesitamos pruebas de la existencia de su cuenta bancaria. Adjunte el documento emitido por el banco, donde podamos ver al propietario de la cuenta y el n\u00famero de cuenta. Puede ser un extracto de cuenta o un contrato de mantenimiento de cuenta. Se acepta copia de documento en papel o archivo PDF. Adjunte el documento aqu\u00ed.",
  "guest_market_table": "Lista de precios",
  "validation_error_not_matching_passwords": "Las contrase\u00f1as no coinciden",
  "react_send_from_amount_here": "Enviar {{amount}} <0>{{currency}}</0> a la siguiente direcci\u00f3n:",
  "react_we_received_your_payment_but_you_decided_to_get_it_we_will_send_it_shortly": "Recibimos su pago pero ha decidido la devoluci\u00f3n. Le enviaremos {{amount}} <0>{{currency}}</0> en breve.",
  "react_we_received_currency_from_your_order_and_we_will_send_you_currency_to": "Hemos recibido {{amountFrom}} <0>{{currencyFrom}}</0> por su orden y le enviaremos {{amountTo}} <1>{{currencyTo}}</1>",
  "react_confirming_incoming_crypto_transaction_waiting_for_more_confirmations": "Vemos la transacci\u00f3n criptogr\u00e1fica entrante por {{amount}} <0>{{currency}}</0>. En este momento estamos esperando mas confirmaciones.",
  "react_confirming_outgoing_crypto_transaction_waiting_for_more_confirmations": "Enviamos la transacci\u00f3n criptogr\u00e1fica saliente por {{amount}} <0>{{currency}}</0>. En este momento, estamos esperando m\u00e1s confirmaciones.",
  "react_suspended_widget_accept_btn": "Aceptar {{amount}} {{currency}}",
  "react_suspended_widget_return_btn": "Devolver {{amount}} {{currency}}",
  "react_order_created_high_market_volatility_message": "Debido a la alta volatilidad del mercado, podemos mantener esta tasa solo por {{duration}} minutos. La velocidad de su transacci\u00f3n criptogr\u00e1fica depende de la tarifa de transacci\u00f3n, por lo que es importante usar la tarifa correcta para que la transacci\u00f3n se verifique pronto. Si su pago llega despu\u00e9s de este tiempo y, al mismo tiempo, la tasa cambia notablemente, nos comunicaremos con usted por correo electr\u00f3nico para darle estas opciones- una nueva tasa de cambio y proceder con el pedido o, devolverle sus fondos.",
  "react_order_suspend_over_limit_message": "El pedido ha sido suspendido, ha alcanzado el volumen m\u00e1ximo mensual de intercambio. Necesita subir de categor\u00eda al nivel {{level}}.",
  "chart_timeframe_option_all": "Todo",
  "react_bank_account_file_upload_unverified_paragraph": "Tambi\u00e9n necesitaremos ver las transacciones bancarias entrantes de esta cuenta para poder ver el nombre titular de la cuenta. Si planea enviar fondos de esta cuenta, no se necesita ning\u00fan paso adicional. En cambio, si planea recibir fondos en esta cuenta con transacci\u00f3n por primera vez, env\u00ede primero cualquier cantidad m\u00ednima a <0>cualquiera de nuestras cuentas</0>.",
  "react_2fa_qr_code_description": "Para mayor seguridad de su cuenta, puede habilitar el doble factor de autenticaci\u00f3n. Usamos Google Authenticator. Para comenzar a usarlo, instale la aplicaci\u00f3n <0>Google Authenticator aplicaci\u00f3n</0> en su tel\u00e9fono, escanee el c\u00f3digo QR que se muestra en esta p\u00e1gina, ingrese la contrase\u00f1a actual de la cuenta y c\u00f3digo desde la aplicaci\u00f3n.",
  "chart_timeframe_option_day": "D\u00eda",
  "chart_timeframe_option_month": "Mes",
  "chart_timeframe_option_week": "Semana",
  "chart_timeframe_option_year": "A\u00f1o",
  "chart_timeframe_option_year_to_date": "Este a\u00f1o",
  "react_profile_page_level1_paragraph_before_form": "El nivel 1 permite comercializar un volumen mensual equivalente a {{limit_level_2}} EUR",
  "react_profile_page_level2_paragraph_before_form": "El nivel 2 permite a los clientes negociar el volumen mensual acumulado por encima del equivalente {limit_level_2} EUR. En algunos casos, se puede realizar preguntas adicionales sobre la Prevenci\u00f3n de Blanqueo de Capitales (PBC) ",
  "react_page_header_posts_by_category": "Categor\u00eda: {{name}}",
  "chart_timeframe_option_3months": "3 meses",
  "ticket_load_more": "Cargar m\u00e1s",
  "ticket_type_your_message": "Escriba su mensaje aqu\u00ed...",
  "no_results_found": "No se encontr\u00f3 resultados.",
  "validation_field_required": "Este campo es obligatorio",
  "validation_min_characters": "El campo debe tener al menos ${min} caracteres",
  "uploaded_file_information_complete": "La informaci\u00f3n de este documento est\u00e1 completa",
  "uploaded_file_click_to_fill_information": "Hacer click aqu\u00ed para completar informaci\u00f3n adicional",
  "react_order_header": "Pedido {{id}}",
  "profile_page_header_my_personal_data": "Informaci\u00f3n personal",
  "validation_error_incorrect_phone_number": "N\u00famero de tel\u00e9fono incorrecto",
  "react_file_upload_extension_error": "S\u00f3lo los siguientes formatos est\u00e1n permitidos: {{extensions}}",
  "qr_code_scan_success": "C\u00f3digo QR escaneado con \u00e9xito",
  "upload_success": "La carga fue exitosa",
  "button_open_form": "Abrir formulario",
  "client_bank_trade_volume_label": "Volumen comercial de la cuenta bancaria",
  "qr_payment_hint": "Puede usar QR Platba. Escanee el siguiente c\u00f3digo utilizando la aplicaci\u00f3n de banca inteligente en su tel\u00e9fono",
  "react_signup_to_get_trading_volume_or_personal_discount": "<0>Registrarse</0> y obt\u00e9n volumen de transacci\u00f3n y/o descuento personal.",
  "sidebar_user_traded_volume": "{{tradeLimitDays}} volumen de d\u00edas: {{traded}}/{{limit}}EUR",
  "trading_limits_info_message": "Volumen limite no verificado: {{limitLevel1}} EUR/{{level1TradeLimitDays}} d\u00edas <br/>\nL\u00edmite de volumen mensual de nivel 1:  {{limitLevel2}} EUR/{{level2TradeLimitDays}} d\u00edas <br/>\nL\u00edmite de volumen del nivel 2 no est\u00e1 formalmente limitado",
  "sidebar_user_traded_volume_sum": "Volumen de intercambio: {{traded}} EUR",
  "react_refund_widget_already_chosen_refund_address": "Ha elegido {{refundAddress}} como direcci\u00f3n de reembolso",
  "react_we_received_your_crypto_payment_but_you_decided_to_get_it_we_will_send_it_shortly": "Recibimos sus monedas, pero usted decidi\u00f3 la devoluci\u00f3n. La opci\u00f3n de devoluci\u00f3n tiene una tarifa adicional, recibir\u00e1 {{amount}} <0>{{currency}}</0> de regreso a su direcci\u00f3n de reembolso.",
  "refund_widget_btn_change_refund_address": "Cambiar direcci\u00f3n de reembolso",
  "react_order_crypto_return_fee_info_message": "La opci\u00f3n de devoluci\u00f3n tiene una tarifa adicional, recibir\u00e1 {{amount}} <0>{{currency} </0> de regreso a su direcci\u00f3n de reembolso.",
  "react_suspended_widget_return_currency_btn": "Devuelva {{amount}} {{currency}} a esta billetera",
  "we_will_send_you_crypto_back_shortly": "Le enviaremos las monedas en breve",
  "button_remove_limit": "Eliminar l\u00edmite",
  "react_alert_limit_removed": "L\u00edmite eliminado con \u00e9xito",
  "unconfirmed_email_please_confirm_or_resend": "Confirme su correro electr\u00f3nico o <0>Reenviar correo electr\u00f3nico de activaci\u00f3n</0>",
  "button_back": "Regresar",
  "button_next": "Siguiente",
  "button_submit_profile": "Enviar perfil",
  "you_need_to_log_out_before_seeing_anonymous_order": "Debe cerrar sesi\u00f3n antes de ver un pedido an\u00f3nimo",
  "react_form_rate_explenation": "\u00bfC\u00f3mo se calcula su precio?",
  "sign_up_to_return_guest_order": "Para devolver su pedido, reg\u00edstrese y agregue una billetera.",
  "error_page_not_found": "P\u00e1gina no encontrada",
  "react_page_affiliate_withdraw_request_button": "Retirar {{amount}} {{currency}}",
  "affiliate_withdraw_history_table_converted_amount": "Cantidad cambiada",
  "react_page_affiliate_dashboard_copy_url_paragraph": "Copie la URL a continuaci\u00f3n y comp\u00e1rtala en su blog, redes sociales o con sus amigos. Cualquiera que haga clic y se registre o cree un pedido dentro de {{duration}} d\u00edas se asociar\u00e1 a su cuenta de afiliado. Recibir\u00e1 una comisi\u00f3n por cada intercambio realizado por el usuario que invit\u00f3.",
  "react_affiliaite_balance_information": "Saldo: {{balance}}<br/>Ganancia totalt: {{totalProfit}}",
  "react_affiliaite_balance_information_tooltip": "El saldo es la cantidad actual que puede retirar. La ganancia total es lo que gan\u00f3 desde que se uni\u00f3 al programa de afiliados.",
  "label_affiliate_overview_joined_at": "Se ha unido",
  "label_repeat_password": "Repetir contrase\u00f1a",
  "react_consent_to_tos": "al continuar, doy mi consentimiento a 0>los t\u00e9rminos de servicio</0> de {{companyName}}",
  "label_delete_account": "Eliminar cuenta",
  "button_delete_account": "Eliminar mi cuenta",
  "delete_account_warning_paragraph": "Si contin\u00faa, los datos de su cuenta se eliminar\u00e1n permanentemente. Lamentamos su retiro. Puede registrarse nuevamente en cualquier momento con la misma direcci\u00f3n de correo electr\u00f3nico, pero su cuenta estar\u00e1 vac\u00eda.",
  "account_delete_success": "Su cuenta ha sido eliminada",
  "log_out_success": "Se ha cerrado la sesi\u00f3n.",
  "session_time_remaining_label": "Tiempo restante de la sesi\u00f3n",
  "session_expire_in_label": "Su sesi\u00f3n expirar\u00e1",
  "order_filter_label": "Filtrar pedidos",
  "order_filter_button_all": "Todo",
  "to_currency_amount_field_label": "Quiero recibir",
  "status_name_unfinished": "Sin terminar",
  "status_description_unfinished": "El pedido a\u00fan no tiene un estado final",
  "filter_drawer_incomplete_filters_warning": "Seleccione al menos un estado en cada categor\u00eda",
  "help_pages_label": "Centro de ayuda",
  "react_file_upload_size_error": "El archivo que intent\u00f3 cargar es demasiado grande. El tama\u00f1o m\u00e1ximo del archivo es {{size}} MB",
  "alert_submit_previous_level_first": "Env\u00ede primero el nivel de verificaci\u00f3n de perfil anterior.",
  "wrong_vs_format_message": "El s\u00edmbolo variable solo debe contener d\u00edgitos (hasta 10)",
  "wrong_ss_format_message": "El s\u00edmbolo espec\u00edfico solo debe contener d\u00edgitos (hasta 10)",
  "wrong_ks_format_message": "El s\u00edmbolo constante solo debe contener d\u00edgitos (hasta 4)",
  "button_extend_session": "Extender la sesi\u00f3n",
  "fiat_payment_recipient_name": "Nombre del beneficiario",
  "fiat_payment_recipient_street": "Calle receptora",
  "fiat_payment_recipient_city": "Cuidad receptora",
  "fiat_payment_recipient_country": "Pa\u00eds receptor",
  "react_you_may_send_money_on_one_of_the_following_bank_accounts_singular": "Enviar {{amount}} <0>{{currency}}</0> a la siguiente cuenta bancaria",
  "crypto_qr_hint": "Puede escanear el siguiente c\u00f3digo o hacer clic en \u00e9l para abrir la billetera en su dispositivo.",
  "react_validation_error_insufficient_funds": "Fondos insuficientes. Tiene s\u00f3lo  {{amount}} {{currencyName}}.",
  "client_settings_label_affiliate_new_income": "Nuevos fondos acreditados",
  "client_setting_hint_affiliate_new_income": "Env\u00edeme un correo electr\u00f3nico cuando obtenga un nuevo cr\u00e9dito en mi cuenta de afiliado.",
  "page_subheader_settings_affiliate": "Afiliarse",
  "check_address_on_trezor_header": "Comprobar direcci\u00f3n en Trezor",
  "check_address_on_trezor_body": "y haga click en continuar",
  "show_address_on_trezor_button_label": "Mostrar direcci\u00f3n en Trezor",
  "send_by_trezor_button": "Pagar con Trezor",
  "send_by_trezor_description_tooltip": "Puede usar esta funci\u00f3n para enviar su pago de forma segura desde el monedero Trezor conectado.",
  "trezor_address_import_dialog_header": "Las siguientes direcciones pertenecen a la cuenta elegida de Trezor. Por favor, seleccione una:",
  "import_from_trezor_button": "Importar desde Trezor",
  "address_used": "usado",
  "trezor_transaction_sent_dialog_title": "La transacci\u00f3n ha sido env\u00edada con \u00e9xito.",
  "trezor_transaction_sent_dialog_content": "El id de la transacci\u00f3n es: {{txid}} Espere un momento hasta que la transacci\u00f3n se vincule a su pedido. Puede utilizar el icono de volver a cargar para actualizar el pedido.",
  "trezor_address_import_available_for_currencies": "Importe de direcciones de Trezor disponible para: {{currencies}}",
  "trezor_link_learn_more": "Aprenda m\u00e1s sobre Trezor en {{brandName}}",
  "page_refresh_button_label": "Refrescar",
  "profile_level_one_send_transfer_instead_of_document_upload": "Enviar\u00e9 una transferencia bancaria desde una cuenta a mi nombre en lugar de cargar un documento.",
  "profile_level1_primary_document_front_label": "La parte frontal del documento principal",
  "profile_level1_primary_document_reverse_label": "El reverso del documento principal",
  "profile_level1_secondary_document_front_label": "La parte frontal del documento secundario",
  "profile_level1_secondary_document_reverse_label": "El reverso del documento secundario",
  "profile_page_level1_primary_document_label": "Documento principal",
  "profile_page_level1_secondary_document_label": "Documento secundario",
  "profile_page_level1_additional_documents_label": "Documentos adicionales",
  "profile_page_level1_document_passport": "Pasaporte",
  "profile_page_level1_document_national_id": "Documento de Identidad",
  "profile_page_level1_document_other": "Otro documento",
  "profile_page_level1_document_driving_license": "Licencia de conducir",
  "profile_page_level1_primary_document_type_select_information": "Elija el tipo de documento principal",
  "profile_page_level1_secondary_document_type_select_information": "Elija el tipo de documento secundario",
  "profile_page_level1_additional_document_type_select_information": "A\u00f1ada documento adicional",
  "profile_page_level1_additional_document_skip_label": "No tengo ning\u00fan otro documento adicional para subir",
  "address_format_bech32_wallet_support_warning": "Esta direcci\u00f3n est\u00e1 en formato bech32, la cual no es totalmente compatible con el monedero Trezor. Para una funcionalidad completa, seleccione \"Segwit accounts\" o \"Legacy Accounts\". La direcci\u00f3n en formato bech32 ahora se puede usar en la billetera electrum.org",
  "label_document_title": "T\u00edtulo del documento",
  "react_new_app_version_available_dialog_title": "La nueva versi\u00f3n de {{brandName}} ya est\u00e1 disponible",
  "update_application_version": "Actualizar",
  "third_party_guest_order_provide_email_paragraph": "Ingrese un correo electr\u00f3nico para continuar",
  "third_party_order_claim_email_verification_message": "Por favor, revise su correo electr\u00f3nico donde encontrar\u00e1 el enlace de confirmaci\u00f3n.",
  "third_party_guest_order_confirm_or_resend_email": "Confirme su direcci\u00f3n de correo electr\u00f3nico o utilice el bot\u00f3n de abajo para enviarla nuevamente.",
  "log_in_to_see_order_registered_client": "Iniciar sesi\u00f3n para ver el pedido",
  "client_recipients_list_header": "Recipientes",
  "sidebar_navigation_recipients": "Recipientes",
  "recipients_filter_label": "Filtrar recipientes",
  "client_add_new_recipient_button": "A\u00f1adir recipiente",
  "reset_filters_button": "Reiniciar",
  "create_new_recipient_header": "A\u00f1adir nuevo recipiente",
  "disable_social_login_label": "Desvincular cuenta de red social",
  "btn_unlink": "Desvincular",
  "alert_error_please_set_password_to_disable_social_login": "Primero configure la contrase\u00f1a de la cuenta para desvincular el inicio de sesi\u00f3n de redes sociales.",
  "disable_social_login_warning_paragraph": "Si contin\u00faa, se desvincular\u00e1 su cuenta de redes sociales. Solo podr\u00e1 iniciar sesi\u00f3n con la contrase\u00f1a de su cuenta de {{brandName}}.",
  "social_login_disable_success": "Inicio de sesi\u00f3n  de redes social desactivado con \u00e9xito",
  "file_attached_to_ticket_auto_message": "Archivo adjunto: {{fileNames}}",
  "network_error_warning": "Vaya, parece que no hay conexi\u00f3n",
  "password_strength_very_weak": "Contrase\u00f1a muy d\u00e9bil",
  "password_strength_weak": "Contrase\u00f1a d\u00e9bil",
  "password_strength_good": "Contrase\u00f1a aceptable",
  "password_strength_great": "Contrase\u00f1a segura",
  "status_description_on_hold": "Pedido en espera",
  "status_direction_description_on_hold": "Pedido en espera",
  "exchange_form_fee_included_tooltip_title": "La tarifa que ve incluye la siguiente tarifa de transacci\u00f3n",
  "exchange_form_fee_not_included_tooltip_title": "Las siguientes tarifas de transacci\u00f3n no est\u00e1n incluidas en la tarifa mostrada. Especifique la cantidad para ver la tasa final.",
  "exchange_form_total_fee_eur": "Tarifa total: ~{{totalFeeEur}} EUR",
  "exchange_form_fee_tooltip_fee_in": "Tarifa de transacci\u00f3n entrante: {{amount}} {{currency}}",
  "exchange_form_fee_tooltip_fee_out": "Tarifa de transacci\u00f3n saliente: {{amount}} {{currency}}",
  "exchange_form_fee_label_no_fee": "Tarifa cero",
  "exchange_form_fee_label_no_fee_explanation": "Esta operaci\u00f3n no tendr\u00e1 tarifa de transacci\u00f3n adicional",
  "exchange_form_fee_label_included": "Incluyendo tarifa de transacci\u00f3n",
  "exchange_form_fee_label_excluded": "Excluyendo tarifa de transacci\u00f3n",
  "order_view_back_to_vendor_button_label": "Volver a {{providerName}}",
  "table_sort_label": "Ordenar",
  "pagination_next_page": "Siguiente p\u00e1gina",
  "pagination_previous_page": "P\u00e1gina anterior",
  "pagination_rows_per_page": "filas por p\u00e1gina",
  "pagination_display_rows": "de",
  "button_download_csv": "Descargar CSV",
  "button_print": "Imprimir",
  "table_view_columns": "Mostrar columnas",
  "table_filter": "Tabla de filtros",
  "table_filters_label": "Filtros",
  "show_columns_label": "Mostrar columnas",
  "table_rows_selected": "fila(s) seleccionada(s)",
  "add_account": "A\u00f1adir cuenta",
  "account_balance": "Balance:",
  "account_minimal_balance_label": "Balance m\u00ednimo:",
  "page_header_accounts": "Cuentas",
  "sidebar_navigation_accounts": "Cuentas",
  "sidebar_navigation_trade": "Intercambiar",
  "landing_page_chart_widget_buy_button": "Comprar",
  "exchange_form_from_amount_label": "Env\u00eda",
  "exchange_form_to_amount_label": "Obtiene",
  "footer_trading_since": "Intercambiando criptomonedas desde 2013",
  "footer_headers_subscribe_to_newsletter": "Suscribirse a nuestro bolet\u00edn",
  "footer_rights_reserved": "\u00a9 {{year}} {{companyName}} Todos los derechos reservados.",
  "landing_header": "Intercambio de criptomonedas confiable desde 2013",
  "landing_subheader": "Negociar de forma f\u00e1cil. <br /> Opera m\u00e1s de 30 pares de tipo de monedas.",
  "landing_button_join": "Unirse",
  "landing_button_about_us": "Acerca de nosotros",
  "landing_recent_orders_header": "Pedidos recientes",
  "landing_statistics_30_day_order_count_label": "N\u00famero de intercambios hechos en los \u00faltimos 30 d\u00edas",
  "landing_statistics_verified_clients_count": "Clientes verificados",
  "landing_statistics_volume_in_currency": "Volumen de 30 d\u00edas en {{currency}}",
  "landing_header_help": "Ayuda",
  "button_buy_now": "Compre ahora",
  "footer_header_support": "Soporte",
  "footer_link_help": "Ayuda",
  "footer_header_company": "Compa\u00f1\u00eda",
  "footer_header_features": "Caracter\u00edsticas",
  "footer_features_trezor_support": "Soporte de Trezor",
  "footer_features_limit_order": "Pedido l\u00edmite",
  "footer_email_newsletter_form_text": "Unirse a nuestro bolet\u00edn para no perderse de noticias importantes de {{brandName}}.",
  "landing_email_newsletter_subscribe_button": "Suscribirse",
  "landing_email_newsletter_subscribe_field_placeholder": "Ingrese su correo electr\u00f3nico aqu\u00ed",
  "landing_email_subscribe_consent_warning": "Al ingresar su correo electr\u00f3nico est\u00e1 aceptando nuestros <0>T\u00e9rminos de servicio</0> y <1>Politica privada</1>",
  "footer_link_transaction_fees": "Tarifas de transacci\u00f3n",
  "footer_link_trade_limits": "L\u00edmites del intercambio",
  "add_the_following_comment_to_payment": "A\u00f1adir el siguiente comentario en el pago",
  "alert_error_please_set_password_to_enable_two_factor": "Por favor establecer contrase\u00f1a de la cuenta para habilitar el doble factor de autenticaci\u00f3n.",
  "alert_success_google_authenticator_disabled_successfully": "Doble factor de autenticaci\u00f3n deshabilitado con \u00e9xito.",
  "alert_success_google_authenticator_enabled_successfully": "Doble factor de autenticaci\u00f3n habilitado con \u00e9xito.",
  "confirmed": "Confirmado",
  "created_at": "Creado el",
  "enter_form_email_placeholder": "Correo electr\u00f3nico",
  "enter_form_password_placeholder": "Contrase\u00f1a",
  "fiat_payments_without_note_cause_delay_warning": "\u00a1Todos los pagos sin concepto ser\u00e1n examinados manualmente, por lo que podr\u00eda causar demora en los pedidos!",
  "form_label_new_password": "Contrase\u00f1a nueva",
  "incoming_bank_transaction": "Transacci\u00f3n bancaria entrante",
  "incoming_crypto_transaction": "Transacci\u00f3n criptogr\u00e1fica entrante",
  "label_2fa_code": "C\u00f3digo 2FA",
  "label_account_constant": "S\u00edmbolo constante",
  "label_account_label": "Etiqueta",
  "label_account_number": "N\u00famero de cuenta",
  "label_account_specific": "Espec\u00edfico",
  "label_account_vs": "S\u00edmbolo variable",
  "label_address": "Direcci\u00f3n",
  "label_address_label": "Etiqueta",
  "label_addresses": "Direcci\u00f3n",
  "label_affiliate_overview_affiliate_id": "Identificaci\u00f3n del afiliado",
  "label_affiliate_overview_estimated_profit": "Pedidos en progreso",
  "label_affiliate_overview_referrals_count": "Usuarios invitados",
  "label_affiliate_overview_url_hits_count": "Clics de Banner",
  "label_amount": "Cantidad",
  "label_arbitrary_data": "Etiqueta de destino",
  "label_bank_account": "Cuenta bancaria",
  "label_bank_account_id": "Cuenta bancaria",
  "label_bank_accounts": "Cuentas bancarias",
  "label_birthday": "Fecha de nacimiento",
  "label_city": "Cuidad",
  "label_confirmations": "Confirmaciones",
  "label_country": "Pa\u00eds",
  "label_created_at": "Creado a las",
  "label_currency": "Moneda",
  "label_current_password": "Contrase\u00f1a actual",
  "label_document_number": "N\u00famero de documento",
  "label_expires_at": "Vence",
  "label_first_name": "Nombre",
  "label_from_amount_currency": "De",
  "label_hash": "Hash",
  "label_id": "Identificaci\u00f3n Personal",
  "label_in_block_id": "Bloquear Identificaci\u00f3n personal",
  "label_is_politician": "Persona Expuesta Pol\u00edticamente",
  "label_issued_by": "Emitido por",
  "label_issued_by_country": "Emitido en el pa\u00eds",
  "label_last_name": "Apellido",
  "label_limit_price": "Tasa L\u00edmite",
  "label_message": "Mensaje",
  "label_monthly_volume": "Volumen mensual",
  "label_new_password_again": "Otra vez la contrase\u00f1a nueva",
  "label_note": "Nota",
  "label_other_account_number": "Cuenta bancaria",
  "label_our_bank_account_id": "Cuenta bancaria de {{brandName}}",
  "label_owner_address": "Direcci\u00f3n del propietario",
  "label_owner_city": "Cuidad del propietario",
  "label_owner_country": "Pa\u00eds del propietario",
  "label_owner_name": "Nombre del propietario",
  "label_paid_at": "Pagado el",
  "label_personal_id": "Identificaci\u00f3n personal",
  "label_phone": "Tel\u00e9fono",
  "label_rate": "Tasa",
  "label_ready_for_verification": "Listo para verificaci\u00f3n",
  "label_receive_address": "Recibir direcci\u00f3n",
  "label_status": "Estado",
  "label_status_direction": "Estado de direcci\u00f3n",
  "label_status_final": "Estado Final",
  "label_street": "Calle",
  "label_ticket_body": "Descripci\u00f3n del Ticket",
  "label_ticket_category": "Categor\u00eda",
  "label_ticket_title": "Asunto",
  "label_to_amount_currency": "A",
  "label_trading_purpose": "Prop\u00f3sito del intercambio",
  "label_trading_source_of_income": "Fuente de ingreso",
  "label_zipcode": "C\u00f3digo postal",
  "mail_client_identification_bullet_point_fill_personal_information": "Complete la informaci\u00f3n personal",
  "oops_something_went_wrong": "Uups, algo sali\u00f3 mal. Por favor, intente de nuevo.",
  "order_id": "ID del pedido",
  "outgoing_bank_transaction": "Transacci\u00f3n bancaria saliente",
  "outgoing_crypto_transaction": "Transacci\u00f3n cripto saliente",
  "page_header_settings": "Ajustes",
  "password_was_changed": "La contrase\u00f1a ha sido cambiada",
  "settings_updated": "Los ajustes fueron actualizados",
  "social_login_with_facebook": "Iniciar sesi\u00f3n con Facebook",
  "social_login_with_vkontakte": "Iniciar sesi\u00f3n con Vkontakte",
  "social_signin_with_google": "Iniciar sesi\u00f3n con Google",
  "social_signin_with_linkedin": "Iniciar sesi\u00f3n con LinkedIn",
  "status": "Estado del pedido",
  "status_description_cancelled": "El pedido est\u00e1 cancelado",
  "status_description_confirming_incoming": "El pedido confirma nueva transacci\u00f3n entrante",
  "status_description_confirming_outgoing": "El pedido confirma nueva transacci\u00f3n saliente",
  "status_description_delivered": "El pedido ha sido entregado",
  "status_description_done": "El pedido est\u00e1 completo",
  "status_description_new": "El pedido ha sido creado, todav\u00eda no hay pago entrante recibido",
  "status_description_proceed": "Se planea que el pedido se ejecute de forma normal.",
  "status_description_received": "El Pedido ha recibido transacci\u00f3n bancaria/cripto y est\u00e1 listo para continuar",
  "status_description_return_to_client": "El pedido est\u00e1 listo para ser devuelto al cliente",
  "status_description_returned": "Transacci\u00f3n del pedido ha sido devuelto al cliente",
  "status_description_suspended": "El pedido ha sido suspendido. Algunas de las posibles razones: se excedi\u00f3 el volumen mensual, se requiere verificaci\u00f3n o usted ha suspendido el pedido manualmente.",
  "ticket_category_affiliate": "Afiliado",
  "ticket_category_api": "Interfaz de Programaci\u00f3n de Aplicaciones (API)",
  "ticket_category_business_verification": "Verificaci\u00f3n de negocio",
  "ticket_category_order": "Pedidos",
  "ticket_category_other": "Otro",
  "ticket_category_security": "Seguridad",
  "ticket_category_suggestion": "Sugerencia",
  "ticket_category_verification": "Verificaci\u00f3n",
  "validation_error_incorrect_email_format": "Formato de correo electr\u00f3nico incorrecto",
  "you_have_accepted_current_suspended_deal": "Ha aceptado la transacci\u00f3n actual suspendida",
  "close": "Cerrar",
  "status_on_hold": "En espera",
  "submit": "Enviar",
  "resend": "Reenviar",
  "reviews": "Escribieron sobre nosotros",
  "label_counter_address": "direcci\u00f3n de la contraparte",
  "react_you_may_send_money_on_one_of_the_following_bank_accounts_plural": "Enviar {{amount}} <0>{{currency}}</0>  a una de las siguientes cuentas bancarias",
  "order_filter_button_new": "Nuevo",
  "order_filter_button_in_progress": "En progreso",
  "order_filter_button_finished": "Terminado",
  "order_filter_button_custom": "Personalizado",
  "client_order_status_base_label": "Estados",
  "client_order_status_direction_label": "Direcci\u00f3n",
  "client_order_status_final_label": "Estados Finales",
  "ticket_status_solved": "Resuelto",
  "ticket_status_in_progress": "En progreso",
  "ticket_status_waiting_for_admin": "Esperando respuesta",
  "ticket_status_waiting_for_client": "Esperando respuesta del cliente",
  "member_of": "Miembro de",
  "level_one_verification_warning_back_transfer_instead_of_statement": "Consulte <0>este art\u00edculo</0> para m\u00e1s detalles",
  "label_bank_account_number": "Bank account number",
  "label_bank_account_details": "Bank account information",
  "validation_max_characters": "El campo no puede contener m\u00e1s de ${max} caracteres",
  "verification_required_if_order_made_warning_for_registered_clients": "In order to proceed with this order you will have to increase your verification level. Your order will be suspended automatically and you will have to accept new rate after the verification is done. For information about limits and verification visit <0>this page</0>.",
  "verification_required_if_order_made_warning_for_guests": "In order to proceed with this order you will have to sign up and then increase your verification level. Your order will be suspended automatically and you will have to accept new rate after the verification is done. For information about limits and verification visit <0>this page</0>.",
  "dialog_text_anonymous_trading_disabled_please_log_in_or_register": "Please log in or sign up to create an order",
  "get_verified_to_start_trading_warning_message": "You need to increase your verification level in order to start trading. You currently have <strong>level {{currentVerificationLevel}}</strong> and need <strong>level {{requiredVerificationLevel}}</strong> to start trading. Please go to the profile tab and fill in the verification form.",
  "offer_will_expire_in": "This offer expires",
  "offer_has_expired": "This offer has expired. Please create a new one on our partner's site or directly on <0>{{company}}</0>.",
  "order_offer_header": "{{companyName}} exchange offer",
  "verification_required_for_order_creation_partner_order": "It is now required to reach verification level {{level}} on {{brandName}} before processing your order. Please sign up and verify your profile in order to continue.",
  "label_specify_destination_tag": "Important: enter {{destinationTag}} as destination tag.",
  "validation_error_required_characters_exactly": "Field ${path} must be exactly ${length} characters",
  "csv_export_column_from_currency": "From currency",
  "csv_export_column_to_currency": "To currency",
  "react_order_crypto_no_fee_info_message": "We do not charge any fees for this order. You will receive full refund.",
  "login_confirmation_form_check_email_paragraph": "A verification code has been sent to your email. Please check your inbox and paste the code below to confirm your login.",
  "login_confirmation_title": "Email verification",
  "login_confirmation_email_resent": "Confirmation email send successfully",
  "login_confirmation_email_not_received": "Did not receive verification email? Please check your spam folder. It can take several minutes.",
  "login_confirmation_code_input_placeholder": "6 digit code",
  "lost_2fa_device_contact_support": "Lost your device? Contact support at {{companyEmail}} or {{companyPhone}} ",
  "higher_verification_limit_required_warning": "Please increase your verification level to see payment instructions",
  "verification_through_onfido_disclaimer": "Identity verification will be performed by Onfido.  ",
  "signup_and_verification_required_warning": "Please <0>sign up</0> and verify your account in order to proceed with your order.",
  "go_to_profile_button": "Go to profile",
  "profile_page_divex_address_ownership_disclaimer_heading": "Wallet security statement",
  "profile_page_divex_address_ownership_disclaimer_message": "Please attach a scan or a photocopy of the declaration of wallet ownership which shows that you are the only one with access to the cryptocurrency wallet that you will use within Divex.",
  "profile_page_divex_address_ownership_disclaimer_warning": "The statement is required to ensure that your advisor does not have access to your cryptocurrency wallet and therefore can not steal your funds in future. </br> If you would like to know more about recommended wallet security, <0>click here</0>.",
  "signup_for_invited_only_divex_message": "Registration is only for Divex exchange clients. </br>\nDo you want to become a client? Contact one of our partners to help you register and set up the entire process. </br>\nThe list of all partners is available <0>here</0>.",
  "divex_address_ownership_disclaimer_upload_label": "Attach document here",
  "email_verification_token_expired_log_in_again": "This verification link is incorrect or has expired. Please log in again",
  "pagination_total_visible_rows": "{visibleCount} of {totalCount}",
  "phone_hint_include_prefix": "Please provide the number with prefix",
  "sidebar_navigation_transactions": "Transactions",
  "account_balance_formatted": "Balance: {{balanceWithCurrency}}",
  "transfer_sent_successfully": "Transfer sent successfully",
  "cookie_consent_i_agree_to_all": "Habilitar todos",
  "cookie_consent_i_agree_to_required": "Habilitar s\u00f3lo cookies funcionales",
  "divex_affil_find_more": "Find out more about affiliate program in <0>this article.<0/>",
  "button_i_confirm": "I confirm",
  "wallet_ownership_declaration_paragraph_1": "I have been advised to use the Trezor hardware wallet for storage of digital assets and use it with PIN code.",
  "wallet_ownership_declaration_paragraph_2": "I am the owner of the master seed (12-24 words) that I have stored securely. I am aware that the cryptocurrencies in the wallet can be spent using the master seed, even without the device itself.",
  "wallet_ownership_declaration_paragraph_3": "The advisor who was helping me set up my wallet did not have access to my master seed and could not make a copy of it.",
  "page_header_exchanges": "Exchange currencies",
  "sidebar_navigation_dashboard": "Dashboard",
  "dashboard_header": "Dashboard",
  "page_header_transfers": "Transfers",
  "settings_button_general_settings": "General",
  "settings_button_security_settings": "Security",
  "settings_button_email_settings": "Rules for sending emails",
  "settings_button_profile_verification": "Profile verification",
  "action_activate_account_email_sent": "We have sent you an e-mail with link for activation of your password to the e-mail address.",
  "action_activate_account_error_email_not_sent": "The e-mail you have provided does not match account that may be activated this way. Please choose another e-mail.",
  "request_account_activation": "Account activation",
  "account_activation_enter_email_provided_to_support": "Activate your Divex account by entering the email you used to register for the Introduction to Bitcoin or Diverzo Academy course.",
  "account_activate_button": "Activate account",
  "account_activation_password_saved": "Your password has been saved. You can now log in.",
  "breadcrumb_category_order": "Order",
  "breadcrumb_subcategory_order_id": "Order #{{id}}",
  "page_header_general_settings": "General settings",
  "page_header_email_settings": "Email rules",
  "dashboard_order_table_last_orders": "Last orders",
  "dashboard_order_table_all_orders": "All orders",
  "user_discount": "Your discount",
  "get_discount": "Get discount",
  "volume_discount": "Volume discount",
  "personal_discount": "Personal discount",
  "ticket_status_all": "All",
  "id": "ID",
  "exchange_form_total_fee": "Total fees",
  "exchange_form_out_fee_crypto": "Outgoing transaction fee",
  "exchange_form_out_fee_fiat": "Outgoing bank transfer fee",
  "exchange_form_in_fee_crypto": "Incoming network fee",
  "exchange_form_in_fee_fiat": "Incoming bank transfer fee",
  "support": "Support",
  "tickets": "Tickets",
  "ticket": "Ticket",
  "message": "Message",
  "upload_file": "Upload file",
  "you": "You",
  "page_become_affiliate_partner_header": "Become Simplecoin <0>affiliate</0> partner",
  "page_become_affiliate_partner_bonus": "Recommend our exchange office to people and get a <strong>  commission of <br> up to {{affiliatePercent}}%</strong> of each exchange they make.",
  "page_become_affiliate_partner_involved": "I want to get involved",
  "page_become_affiliate_partner_information": "More information",
  "page_affiliate_dashboard_header": "Affiliate program",
  "page_affiliate_dashboard_current_balance_header": "Current balance",
  "page_affiliate_dashboard_current_balance_withdraw": "Withdraw balance",
  "order_related_support": "Support related to order",
  "page_affiliate_dashboard_total_profit": "Total profit",
  "page_become_affiliate_partner_copy_link_header": "Your affiliate link",
  "page_become_affiliate_partner_bonus_start": "Start of cooperation:",
  "page_become_affiliate_partner_bonus_tip": "<strong> Tip! </strong> Also use pre-made promotional banners in various sizes and motifs.",
  "page_become_affiliate_partner_promotional": "Show promotional materials",
  "document_issued_by_government": "Document issued by government",
  "document_back_side_scan": "Document back side scan",
  "document_front_side_scan": "Document front side scan",
  "document_type": "Document type",
  "scan_quality_warning": "Good quality scans (300dpi) or photos which are fully readable are required. No parts of any document can be edited nor removed.",
  " page_become_affiliate_partner_income_header": "Overview of income",
  "page_become_affiliate_partner_withdrawal_header": "Overview of withdrawal",
  "page_become_affiliate_partner_statistics_header": "Overwiew of statistics",
  "secondary_document": "Secondary document",
  "selfie_with_id": "Selfie with ID card",
  "affiliate_withdraw_breadcrumb": "Withdraw",
  "affiliate_withdraw_header": "Withdraw affiliate balance",
  "affiliate_withdraw_info_alert": "Your affiliate balance is <strong>{{balanceCurrencyEur}}</strong>. You may withdraw it in any of the supported currencies. You can see the converted amount after selecting the currency and recipient. ",
  "bank_account_create_form_header": "Add a new {{currencyCode}} bank account",
  "crypto_address_create_form_header": "Add a new {{currencyCode}} wallet",
  "settings_social_create_password_button": "Set password",
  "recipient_page_table_name": "Name",
  "recipient_page_table_address": "Address / Bank account no.",
  "recipient_page_table_currency": "Currency",
  "recipient_page_table_header": "List",
  "wallet_welcome_page": "You are about to go to <br /> Simplecoin Wallet",
  "wallet_welcome_page_condions": "I agree with the terms and <0>conditions</0> of Simplecoin Wallet",
  "wallet_welcome_page_button": "Continue to Simplecoin Wallet",
  "wallet_welcome_page_benefits_1": "Create a portfolio at Simplecoin",
  "wallet_welcome_page_benefits_2": "Easy deposit of funds",
  "wallet_welcome_page_benefits_3": "Trade cryptocurrencies easier and faster",
  "wallet_welcome_page_benefits_4": "More advantageous shift fees",
  "wallet_welcome_page_benefits_5": "Priority support",
  "verification_transaction": "Verification transaction",
  "verification_call_info": "As part of the verification process, we also need to verify your phone number, so we will call you to confirm that the verification was completed without any problems. You can choose the approximate time that is most convenient for you for the final phone call.",
  "verification_call": "Verification call",
  "when_should_we_call": "When should we call you",
  "asap": "As soon as possible",
  "email": "E-mail",
  "verification_and_limits": "Verifications and limits",
  "profile_verification": "Profile verification",
  "limits": "Limits",
  "start_with_verification": "Start with verification",
  "verification_in_progress": "Verification in progress",
  "level1": "Level 1",
  "done": "Done",
  "ok": "OK",
  "profile_verification_done_info": "We've emailed you the verification payment information in case you want to get back to them. We will let you know by email that you have been verified.",
  "sidebar_navigation_portfolios": "Porfolio",
  "page_dashboard_insert_deposit": "Deposit funds",
  "page_trade_head_title": "Trade",
  "transaction_tabs_title_exchange": "Exchange",
  "transaction_tabs_title_deposit": "Deposit",
  "transaction_tabs_title_withdraw": "Withdraw",
  "transaction_tabs_title_transfer": "Transfer",
  "label_summary": "Summary",
  "increase_limit": "Increase limit",
  "read_only": "Read-only",
  "page_portfolio_header": "Portfolio",
  "amount": "Amount",
  "purpose_of_crypto_trading": "Purpose of trading with cryptocurrencies",
  "what_is_purpose_of_crypto_trading": "What is your purpose of trading with cryptocurencies",
  "get_familiar_with_cryptocurrencies": "Get familiar with cryptocurencies",
  "occasional_buing_selling": "Occasional buying/selling",
  "one_off_investment": "One-off investment",
  "regular_savings": "Regular savings",
  "other": "Other / Sale of cryptocurrency",
  "speculations": "Speculations",
  "describe_your_purpose_of_cryptotrading": "Describe your purpose of trading with cryptocurrencies",
  "today": "today",
  "morning": "morning",
  "evening": "evening",
  "afternoon": "afternoon",
  "tomorrow": "tomorrow",
  "proof_of_income": "Proof of income",
  "proof_of_income_info": "The Anti-Money Laundering and Anti-Terrorism Financing Act (AML Act) requires to verify the source of your income if you want to trade with higher amounts. We therefore need to ask you some questions about the origin of the money you want to trade with us.",
  "income": "Income",
  "one-off_income": "one-off income",
  "monthly_income": "monthly income",
  "quarterly_income": "quarterly income",
  "yearly_income": "yearly income",
  "frequency": "Frequency",
  "source_of_income": "Source of income",
  "employment": "Employment",
  "position_in_company": "Position in company",
  "company_name": "Company name",
  "running_own_business": "Running own business",
  "rental": "Rental",
  "share_of_profit": "Share of profit",
  "pension": "Pension",
  "documents_supporting_the_income": "Documents supporting the income",
  "income_source_description": "Description",
  "add_income": "Add next income",
  "footer_sidebar_link_blog": "Blog",
  "proof_of_basic_income": "Proof of basic income",
  "proof_of_basic_income_info": "In order to set at least a basic trading and deposit limit, we need to know if you have at least a minimum income. There is no problem to increase this basic limit later if you want to trade \"at full throttle\". Even if you don't have that income, it is still OK. Later you will describe the source of the funds you want to trade with and we will set the limit accordingly.",
  "step": "Step",
  "basic_income_agreement": "I confirm that my income corresponds to at least 600 EUR per month",
  "semi-yearly_income": "semi-yearly income",
  "incomes": "Incomes",
  "income_state_submited": "awaits approval",
  "income_state_approved": "approved",
  "income_state_new": "new",
  "updating_incomes_info": "Any modification of your incomes (adding a new income or deleting an existing one) must be assessed by us and we will adjust your trading limit accordingly.",
  "submit_for_approval": "Submit for approval",
  "income_state_unapproved_change": "unapproved change",
  "prooved_incomes": "Prooved incomes",
  "verification_and_incomes": "Verification and incomes",
  "trading_limits": "Trading limits",
  "current_allowed_trading_amount": "Allowed amount for trading",
  "current_allowed_trading_amount_info": "This is the maximum amount you can right now deposit into Simplecoin Wallet or exchange in Simplecoin Exchange. This amount changes over time depending on how often you trade. It can gradually decrease to zero or increase back to your original limit. Only paid orders and confirmed deposits counts.",
  "individual_limits": "Individual limits",
  "time_period": "Time period",
  "limit_extension": "Limit extension",
  "remaining_limit_amount": "Remaining amount you can still draw down",
  "state_of_limit_execution": "State of limit execution",
  "in_time_period": "in",
  "individual_trading_limits_info": "If you have more individual limits, you must meet all of them. The one that is currently the most restrictive is always applied.",
  "trading_limit_extension_info": "If the standard limit is not enough for you, we can increase your limit even further by providing proof of one-off income. We will gradually reduce this limit whenever you trade above the standard limit.",
  "income_state_deleted": "deleted",
  "account_update_success": "Account was updated",
  "account_label_edit": "Edit account",
  "account_action_archive": "Archive account",
  "waiting_for_payment": "waiting for payment",
  "waiting_for_payment_approval": "waiting for payment approval",
  "verification_transaction_info": "For successful remote identification, you will need to verify at least one of your bank accounts (in your name). For this we only need to receive a single euro from this account.",
  "verification_transaction_received_info": "The verification transaction has been received. All we have to do is verify that it came from your bank account.",
  "verification_transaction_approved_info": "Your bank account has been verified. So we can skip this step.",
  "export_to_csv": "Export to CSV",
  "new_account_button": "Create new Account",
  "remove_recipient": "Remove recipient",
  "recipient_detail": "Recipient detail",
  "account_label": "Account label",
  "veriffied": "veriffied",
  "not_verified": "not verified",
  "label_create_trade": "Create trade",
  "remove_income": "Remove income",
  "transaction_detail_header": "Transaction detail",
  "transaction_type": "Type",
  "trade": "Trade",
  "withdraw_label_about": "You are about to withdraw",
  "withdraw_summary_fees": "Summary and fees",
  "Choose-destination-bank-account": "Choose destination bank account",
  "wallet_account_destination": "Chose destination {{currencyName}} address",
  "account_unique_name_label": "Provide a unique name of this account",
  "account_note_label": "You can add any additional note here",
  "account_detail_suspend_alert": "Your account is suspended. Please email us for support.",
  "current_balance_label": "Current balance",
  "balance_blocked_label": "Blocked balance",
  "label_not_choosen_account": "You do not have an account selected",
  "label_not_enough_money": "You have insufficient funds",
  "label_set_all_funds": "Set all funds",
  "label_available_balance": "Available balance",
  "label_withdraw": "Withdraw",
  "account_label_description": "Account description",
  "label_balance_after_transaction": "Balance after transaction",
  "label_fee": "Fee",
  "label_counter_party": "Counterparty",
  "label_operation_confirm_withdraw": "Confirm your withdrawal",
  "label_operation_confirm_transfer": "Confirm your transfer",
  "label_operation_confirm_exchange": "Confirm your exchange",
  "label_no_account_this_currency": "You do not have an account in this currency",
  "transaction_info_acc_head": "Amount and currency",
  "label_from_account": "From account",
  "label_target_account": "Target account",
  "exchanges_balances_alert_fiat": "Conversion between non-crypto is currently not possible",
  "account_create_success": "Account was created",
  "withdraw_label_requests": "Withdraw requests",
  "operations_label_dashboard_header": "Operations",
  "label_currency_general": "Base currency",
  "income_state_draft": "unverified",
  "label_client_currency_balance": "Balance in base currency",
  "label_show_all": "Show all",
  "dashboard_balances_last_transactions": "Latest transactions",
  "dashboard_balances_no_accounts": "You don't have an account yet",
  "dashboard_balances_no_withdrawal_requests": "No withdrawal requests",
  "dashboard_balances_no_transaction": "You don't have transactions yet",
  "general_settings_label_base_currency": "This currency will be used as default throughout the app",
  "general_settings_header_base_currency": "Base currency",
  "page_portfolio_closed_suspend_accounts_header": "Suspended and canceled Accounts",
  "confirm_withdraw_button": "Withdraw",
  "button_withdraw_continue_to_confirmation": "Continue",
  "recipient_name": "Recipient name",
  "button_create_transfer": "Create transfer",
  "button_transfer_continue_to_confirmation": "Continue",
  "button_exchange_continue_to_confirmation": "Continue",
  "total_balance": "Total balance",
  "no_trading_limit_extension": "There is no extension of the trading limit.",
  "deposit_component_automatic_create_acc_label": "You do not have an account at Simplecoin in this currency yet. We'll set it up for you automatically.",
  "deposit": "Deposit",
  "withdraw": "Withdraw",
  "create_default_account_label": "Default account for",
  "create_default_account_description": "Automatically created account when depositing funds",
  "create_default_account_button": "Create default {{currencyCode}} account",
  "dashboard_blog_posts_header": "Last blog posts",
  "dashboard_link_to_blog": "Blog",
  "credited_amount": "Credited amount",
  "exchange_rate": "Exchange rate",
  "accounting_time": "Accounting time",
  "date": "Date",
  "amounts": "Amounts",
  "success_order_header": "What's next? Pay for your order to complete.",
  "success_order_email": "We have sent you payment instructions by email <0>{{email}}</0>.",
  "finop_type_DEPOSIT": "deposit",
  "finop_type_EXCHANGE": "exchange",
  "finop_type_TRANSFER": "transfer",
  "finop_type_WITHDRAWAL": "withdraw",
  "exchange_made": "Exchange successful",
  "from_account": "From account",
  "to_account": "To account",
  "accounts": "Accounts",
  "portfolio_balance_summary_fiat_header": "Fiat:",
  "portfolio_balance_summary_crypto_header": "Cryptocurrency:",
  "order_success_check_crypto": "Please check if your wallet address is correct.",
  "success_order_label_id": "Your order number is <0>{{order_id}}</0> created. Thank you!",
  "exchange_form_crypto_address_field_label": "Cryptocurrency address",
  "order_success_check_bank": "Please check if the bank account is correct.",
  "other_currencies": "Other currencies",
  "no_funds_in_portfolio": "You have no funds in your portfolio now. Deposit fiat or crypto funds into your accounts and then you can start trading immediately.",
  "exchange_form_register": "for higher limits.",
  "list": "List",
  "withdrawal_request_status_new": "Pending",
  "withdrawal_request_status_confirming_outgoing": "Dispatched",
  "withdrawal_request_status_done": "Done",
  "withdrawal_request_status_canceled": "Cancelled",
  "landing_main_header": "<0>The biggest</0> cryptocurrency exchange in Czechia",
  "next_landing_subheader": "Exchanging cryptocurrencies <0>since 2013</0>.",
  "header_link_about_us": "About us",
  "header_link_get_started": "Get started",
  "header_link_blog": "Blog",
  "header_service_hours": "Lun-Dom 9-21",
  "buy_now_button": "Buy now",
  "chart_header": "Buy and sell",
  "exchange_form_label": "Buy and sell",
  "how_it_works_paragraph_1_content": "It only takes a minute to place an order. Up to 200 \u20ac / 10 days, all we need is your email and cryptocurrency wallet address. From higher amounts you will need to register and get verified.",
  "how_it_works_paragraph_1_heading": "Order",
  "how_it_works_paragraph_2_heading": "Pay",
  "how_it_works_paragraph_3_heading": "Receive cryptocurrency",
  "how_it_works_paragraph_2_content": "Once your order is complete, we will provide you with payment instructions.",
  "how_it_works_paragraph_3_content": "Upon receiving of your payment, we will send the cryptocurrency to your wallet. We can usually do it within 30 minutes!",
  "landing_support_section_paragraph": "Need help with exchanging cryptocurrencies or using the Trezor hardware wallet? Contact us anytime via email, phone, or visit our client center for personalized assistance.",
  "landing_support_section_heading": "Real people, real customer support",
  "contact_us_button": "Contact",
  "landing_advantages_1_heading": "Simple and safe cryptocurrency trade",
  "landing_advantages_1_text": "In only takes a minute to create an order. And once the funds are credited to our account, we will manually verify the transaction and send you the cryptocurrency within 30 minutes on average.",
  "landing_advantages_2_heading": "Real people, real customer support",
  "landing_advantages_3_heading": "Biggest and oldest exchange in Czechia",
  "landing_advantages_2_text": "Need advice on exchanging cryptocurrencies? You can always write, call or send us a ticket. We handle most requests promptly.",
  "landing_advantages_3_text": "We have been exchanging cryptocurrencies since 2013. We have exchanged cryptocurrencies for more than 21 thousand people with a total value of over 8 billion CZK. We are one of the founding members of <0>\u010cKMA</0>.",
  "ckma_founder": "Founding member of",
  "newsletter_field_placeholder": "Your e-mail",
  "footer_sub_header_newsletter_subscription": "Sign up for news",
  "footer_link_career": "Career",
  "footer_link_affiliate_program": "Affiliate program",
  "footer_link_privacy_notice": "Privacy",
  "footer_link_terms_and_conditions": "Terms and conditions",
  "footer_link_contact": "Contact",
  "footer_link_limits_and_verification": "Limits and account verification",
  "footer_link_faq": "Frequently asked questions",
  "footer_link_blog": "Blog",
  "footer_header_company_info": "We have exchanged over 8 billion Czech Crowns since 2013.",
  "landing_bottom_section_header_trade_easily": "Trade coins with ease",
  "landing_bottom_section_button_buy_coins": "Buy cryptocurrency",
  "how_it_works_header": "How does it work?",
  "landing_wrote_about_us_header": "Wrote about us",
  "landing_last_orders_header": "Last transactions",
  "header_faq": "Frequently asked questions",
  "faq_general_questions_header": "General",
  "log_in_button": "Log in",
  "header_button_buy_crypto": "Buy cryptocurrency",
  "header_short_button_buy": "Buy",
  "search_button": "Search",
  "header_support": "Support",
  "send_button": "Send",
  "email_subscribe_header": "Subscribe for news from the cryptocurrency world",
  "affiliate_advantage_payout_anytime": "Payout possible at any moment",
  "affiliate_advantage_banner": "Banners for your website or blog",
  "affiliate_advantage_referral_link": "Own referral link",
  "affiliate_advantage_fiat_or_crypto": "Get paid in crypto or fiat currency",
  "affiliate_advantage_cookie": "Cookies for {{affiliateCookieValidityDays}} days",
  "affiliate_advantage_reward_from_margin": "{{affiliateMarginReward}}% reward from our margin",
  "affiliate_header": "Affiliate program",
  "affiliate_subheader": "Become a Simplecoin affiliate partner and get commission from every exchange done by people you brought.",
  "header_earn_with_us": "Earn with {{brand}}",
  "create_account_button": "Sign up",
  "affiliate_how_it_works_header": "How does it work?",
  "affiliate_how_it_works_step_1_header": "Create an account and sign up for our affiliate program",
  "affiliate_how_it_works_step_2_header": "A unique link will be created for you",
  "affiliate_how_it_works_step_3_text": "Every person who comes to the Simplecoin site via your link receives a cookie. If they create an order within 7 days, you will earn a profit of {{affiliateMarginReward}}% of our margin. You will earn these returns for every order he trades with us. For orders created after the 7 day limit, the link is no longer active.",
  "affiliate_how_it_works_step_2_text": "You have a URL and banners that you can share on your website, blog, social media or with your friends. Anyone who uses this link and then signs up or creates an order within 7 days will be entered into your affiliate program for two years.",
  "affiliate_how_it_works_step_1_text": "Both registration and verification are required to join the affiliate program. Registration allows you to access the administration and manage your account. Verification is required for payment of rewards. We follow good manners and do not encourage unfair practices or dealings. We expect the same from our partners.",
  "affiliate_how_it_works_step_4_text": "You can view how much you have earned at any time in your affiliate program administration. When you feel like it, you can choose the amount in crypto or fiat currency. We will send the payment to your bank account or crypto wallet.",
  "affiliate_how_it_works_step_3_header": "You will receive {{affiliateMarginReward}}% from our profit margin for every customer you bring",
  "affiliate_how_it_works_step_4_header": "You can withdraw your earnings at any time",
  "limits_and_verification_header": "Limits and verification",
  "limits_and_verification_subheader": "For legal reasons, like all other exchanges, we must <0>verify every transaction above a certain limit.</0> If this applies to you, you can easily register and have your account verified. See the boxes below for information on limits and verification.",
  "limits_table_level_0_header": "up to {{limitLevel0Eur}} \u20ac",
  "limits_table_level_0_subheader": "per {{limitLevel0DurationDays}} days",
  "limits_table_registration": "Compulsory registration and identification",
  "limits_table_row_2_documents_photo": "Photo of 2 personal documents",
  "limits_table_row_selfie": "Selfie with document",
  "limits_table_row_verification_bank_transfer": "Verification bank transfer",
  "limits_table_row_verification_phone_call": "Verification phone call",
  "limits_table_row_purpose_od_crypto_purchase": "Purpose of the purchase",
  "limits_table_row_source_of_income": "Source of income",
  "limits_table_row_individual_limits_base_on_income_source": "Individual limits based of source of income",
  "limits_table_level_1_header": "{{limitLevel0Eur}} \u2014 {{limitLevel1Eur}} \u20ac",
  "limits_table_level_1_subheader": "per {{limitLevel1DurationDays}} days",
  "limits_table_level_2_subheader": "per {{limitLevel2DurationDays}} days",
  "limits_table_level_2_header": "over {{limitLevel2EurStart}} \u20ac ",
  "footer_mobile_navigation_expand_label": "Site navigation",
  "support_search_placeholder": "What would you like to know?",
  "error_404_header": "We couldn't find that page",
  "error_404_subheader": "But you can continue to...",
  "error_page_contact_us": "If you think this is a mistake, write us on {{supportEmail}}. Thank you!",
  "link_blog_label": "What's new on Simplecoin",
  "link_contact_label": "Contact us",
  "link_faq_label": "Find answers here",
  "about_page_recommendations_section_header": "Recommended by",
  "cookie_dialog_text_content": "{{brand}} uses cookies to make using our services more comfortable and to constantly improve user experience. You can can choose which types of cookies will be used below. For more information read our <0>cookie policy</0>",
  "cookie_dialog_necessary_cookies_header": "Necessary cookies",
  "cookie_dialog_necessary_cookies_text": "Cookies which are needed for the functioning of the website",
  "cookie_dialog_necessary_cookies_always_active": "Always active",
  "cookie_dialog_statistical_cookies_header": "Statistical cookies",
  "cookie_dialog_statistical_cookies_text": "Cookies which help us measure and understand how you use our services",
  "cookie_dialog_marketing_cookies_header": "Marketing cookies",
  "cookie_dialog_marketing_cookies_text": "Cookies that help us in some way",
  "cookie_dialog_personalization_cookies_header": "Personalization cookies",
  "cookie_dialog_personalization_cookies_text": "Cookies thanks to which you will not need to set some things twice",
  "cookie_dialog_accept_all_button": "Accept all",
  "cookie_dialog_confirm_choices_button": "Confirm my choices",
  "cookie_dialog_header": "Your cookie preferences",
  "support_label_breadcrumbs_navigation": "Help",
  "buy_currency_button": "Buy {{currencyName}}",
  "exchange_form_get_wallet_help": "No wallet?",
  "scan_address_qr_code": "Scan QR",
  "exchange_form_crypto_address_field_placeholder": "bc1...",
  "order_form_email_and_account_step_label": "Order information",
  "order_form_amounts_step_label": "Amount and currency",
  "order_form_header": "Order",
  "landing_login_dialog_title": "Introducing Simplecoin Pro. A new platform with lower fees and instant currency exchanges. No need for a crypto wallet.\n",
  "landing_login_dialog_pro_header": "Try Simplecoin Pro",
  "landing_login_dialog_exchange_header": "Go to Simplecoin Exchange",
  "landing_login_dialog_exchange_text": "Simple crypto exchange service you know and love",
  "landing_login_dialog_pro_text": "Try Pro for even better trading experience",
  "landing_login_dialog_pro_login_button": "Log in to Pro",
  "landing_login_dialog_exchange_login_button": "Log in to Exchange",
  "about_us_section_small_header": "About us",
  "about_us_section_1_header": "We make the world of buying or selling <0>Bitcoin</0> and other digital currencies <0>easier</0>.",
  "about_us_section_1_subheader": "The world of cryptocurrencies erases boundaries and brings you real freedom and a new perspective on finance.",
  "about_us_section_2_header": "We are cryptocurrency experts",
  "about_us_section_2_subheader": "We help put money back to regular people's hands",
  "about_us_section_2_content": "Simplecoin is first and foremost a technology company that relies on the expertise of our programmers. We invest time and attention specifically in a high standard of security for your client accounts and data.",
  "about_us_section_3_header": "We've been making it easy for people to buy cryptocurrencies <0>since 2013</0>",
  "about_us_section_3_text": "From the very beginning, we have been a purely Czech company with a clear ownership structure. Anyone can trade cryptocurrencies. We bring another possibility of working with finances that are not managed by banks and the state. We bring you options that you manage yourself. We show people that the cryptocurrency world is simple.",
  "about_us_section_4_header": "Modern technology and security",
  "about_us_section_4_subheader": "We take care of the high security of user accounts",
  "about_us_section_4_text": "Simplecoin is primarily a technology company. We base our experience on the expert knowledge of our programmers. We invest time and attention in a high level of security for our clients' accounts and data.",
  "contact_header": "Get in touch",
  "contact_page_email_label": "Email",
  "contact_page_phone_label": "Phone",
  "contact_page_customer_center": "Customer center",
  "contact_page_billing_info": "Billing information",
  "support_search_result_count": "{{resultsCount}} results",
  "limits_table_item_not_needed": "No",
  "limits_table_item_required": "Required",
  "how_to_get_started_step_1_name": "Step 1",
  "how_to_get_started_step_1_header": "You need a crypto wallet",
  "how_to_get_started_step_1_cta": "How to set up a crypto wallet",
  "how_to_get_started_step_1_text": "The wallet is used to store cryptocurrencies. You will need it when making a purchase. If you don't have a wallet, you can download it as an app on your mobile phone or computer. We'll show you how. ",
  "how_to_get_started_step_2_name": "Step 2",
  "how_to_get_started_step_2_header": "Sign up",
  "how_to_get_started_step_2_subheader": "It has its advantages",
  "how_to_get_started_step_2_text": "You will need an email address and a secure password. You can place an order without registering, but you will lose the volume discount and the ability to manage your order in your user account.",
  "how_to_get_started_step_3_name": "Step 3",
  "how_to_get_started_step_3_header": "Create an order",
  "how_to_get_started_step_3_subheader": "Now it only takes a minute",
  "how_to_get_started_step_3_text": "Fill out the order form - you already have everything you need (email address and crypto wallet address).  We will send you payment instructions by email. Once we have verified the funds have been credited, we will send you the desired currency. We can usually do this within 30 min.",
  "how_to_get_started_step_3_hint": "Pay attention to payment instructions. Remember to insert the text in the message to the recipient as instructed. This will help us process your order faster.",
  "how_to_get_started_step_4_name": "",
  "how_to_get_started_step_4_header": "",
  "how_to_get_started_step_4_subheader": "",
  "how_to_get_started_step_4_text": "",
  "how_to_get_started_step_4_cta": "",
  "landing_clients_since_2013": "clients since 2013",
  "landing_monthly_volume": "of volume in the last 30 days ",
  "landing_exchanges_per_month": "exchanges in the last 30 days",
  "cookie_bar_header": "<0>Simplecoin</0> uses cookies to function correctly",
  "cookie_bar_text": "We use cookies to for login as well as navigation and traffic analysis. Thank you for your consent to their use. Select your cookies.",
  "cookie_bar_agree_and_continue_button": "Agree and continue",
  "cookie_bar_manage_cookie_settings_button": "Cookie preferences",
  "order_detail_info_header": "Order details",
  "order_detail_order_id": "Order number",
  "order_detail_created_at": "Created at",
  "order_detail_status": "Status",
  "order_detail_rate": "Order rate",
  "order_detail_button_cancel": "Cancel",
  "order_detail_button_suspend": "Suspend",
  "order_detail_button_download": "Download in PDF",
  "order_detail_button_new_ticket": "Create a ticket",
  "order_detail_history_table_header": "Order history",
  "order_history_datetime_column_name": "Date and time",
  "order_history_status_column_name": "Status",
  "order_detail_outgoing_payment_label": "Outgoing payment",
  "order_detail_incoming_payment_label": "Your payment",
  "order_detail_incoming_payment_waiting_for_transaction": "Your transaction has not been detected yet",
  "order_detail_incoming_payment_waiting_for_outgoing_transaction": "We are waiting for your payment",
  "order_payment_instructions_header": "Send payment with the following details",
  "order_form_payment_instructions_bank_account_number_label": "Bank account number",
  "order_form_payment_instructions_bank_amount_label": "Amount",
  "order_form_payment_instructions_bank_message_label": "Payment message",
  "order_form_payment_instructions_use_qr": "Or scan the following QR code",
  "order_form_payment_instructions_choose_one_of_company_accounts": "Company account",
  "order_status_done": "Done",
  "order_status_delivered": "Delivered",
  "order_status_canceled": "Canceled",
  "order_status_returned": "Returned",
  "order_status_in_progress": "In progress",
  "order_status_confirming_incoming": "Confirming incoming",
  "order_status_confirming_outgoing": "Confirming outgoing",
  "order_status_new": "New",
  "order_status_suspended": "Suspended",
  "order_status_received": "Received",
  "order_detail_outgoing_payment_waiting_for_incoming_transaction": "Your transaction has not been detected yet",
  "show_payment_instructions_dialog_opener_button": "Show payment instructions",
  "support_section_header": "Did not find the answer?",
  "support_section_text": "The customer service line can be reached Monday to Sunday between 9:00 and 21:00. Or send us an email. Our colleagues will always be happy to advise you and guide you safely through your purchase.",
  "label_crypto_sent_to_address": "Address",
  "label_transaction_id": "Transaction ID",
  "order_detail_button_proceed": "Proceed",
  "label_amount_and_currency": "Amount",
  "label_payment_sent_at": "Created",
  "order_detail_outgoing_payment_waiting_to_confirm_incoming_transaction": "Incoming transaction is being confirmed",
  "order_detail_outgoing_payment_order_suspended": "Order is suspended, now waiting for your decision. ",
  "order_detail_outgoing_payment_order_return_to_client": "Your payment will be returned",
  "order_form_payment_instructions_company_billing_details": "Billing information",
  "order_has_been_created": "Order successfully created",
  "fiat_payment_instructions_amount_label": "Amount",
  "order_detail_button_return": "Return",
  "order_view_original_price_label": "Previous offer",
  "order_view_limit_price_label": "Limit price",
  "order_view_current_price_label": "Current offer",
  "cryypto_payment_instructions_amount_label": "Amount",
  "payment_instruction_hint_fill_amount_to_show_qr": "Fill amount filed to generate QR code",
  "deposit_payment_instruction_amount_label": "Amount to deposit",
  "form_email_field": "Email",
  "confirm_button": "Confirm",
  "email_placeholder": "Email",
  "change_in_24hours": "Change in the last 24 hours",
  "order_offer_amounts_label": "Your offer",
  "order_offer_your_crypto_address": "Your cryptocurrency address",
  "order_offer_continue_to_login": "Continue with {{companyName}} account",
  "order_offer_or_separator": "or",
  "order_offer_continue_without_registration": "Continue without registration",
  "order_offer_validity_notice": "This offer will expire in <strong>{{timeRemaining}}</strong>. Please log in or continue with email. ",
  "order_offer_continue_as_guest": "Continue as guest",
  "order_offer_existing_email_please_click_continue_button": "This email is already registered. Please choose the \"Continue with {{companyName}} account\" option",
  "landing_header_references": "References",
  "crypto_payment_instructions_amount_label": "Amount",
  "crypto_payment_instructions_destination_tag_field_label": "Destination tag",
  "order_form_mail_help_text": "We will send you instructions for completing the order to this e-mail",
  "validation_required_email": "Enter your e-mail address",
  "validation_required_crypto_account": "Enter your crypto account",
  "landing_help_section_header": "Aqu\u00ed encontrar\u00e1 respuestas a las preguntas m\u00e1s frecuentes. Adem\u00e1s de las instrucciones escritas, tambi\u00e9n podemos ayudarle en nuestra l\u00ednea de atenci\u00f3n al cliente en el n\u00famero +420 608 746 753 o por correo electr\u00f3nico en info@simplecoin.eu, de lunes a domingo de 9 a 21 horas. Podemos guiarle en su primera compra, ayudarle a hacer un pedido o a registrarse, aconsejarle sobre c\u00f3mo configurar su cartera o responder a sus preguntas. No dude en preguntarnos cualquier cosa.",
  "guest_exchange_form_limit_alert": "Purchase without registration is possible up to {{code}} {{limit}}. For higher amounts, please log in or register.",
  "exchange_form_email_label": "Your e-mail",
  "exchange_form_alert_change": "The exchange between these currencies cannot be traded. Please adjust your currency selection.",
  "welcome_in_wallet": "Welcome in Wallet!",
  "start_with_funding_text_2": "You have been verified. Now you can deposit funds and start trading.",
  "start_with_funding_text_1": "Well done!",
  "amount_in_fiat_payment_instructions_info": "The amount is required to generate the QR code",
  "limit_table_level_0_text": "You can buy up to \u20ac{{limitLevel0Eur}} within 10 days without registration and without he need to verify personal documents.",
  "label_go_to_order": "Create Order",
  "label_register": "Register",
  "account_detail": "Account detail",
  "signup_form_subtitle": "You will get a better rate and higher trading limits. You will have an overview of your transactions.",
  "label_guest_login": "If you already have an account <0>Login</0>",
  "label_guest_signup": "If you don't have an account yet, <0>sign up</0>",
  "label_register_success": "Registration was successful",
  "label_register_success_verify": "Please activate your account now",
  "label_register_success_sended_verify": "We have sent you a link to activate your account to the e-mail {{mail}}.",
  "label_account_notes": "Account notes",
  "deposit_request_card_head": "Deposit requests",
  "dashboard_balances_no_deposit_requests": "No deposit requests",
  "deposit_request_status_pending": "Pending",
  "exchange_form_terms_must_be_accepted": "Terms and conditions should be accepted",
  "validation_required_bank_account": "Enter your bank account number",
  "order_status_dispatched": "Dispatched",
  "order_status_paid": "Paid",
  "order_status_paidUnconfirmed": "Paid (1/2)",
  "order_status_tooltip_new": "The order is waiting to be paid.",
  "order_status_tooltip_paidUnconfirmed": "We see an incoming transaction. It is waiting to be confirmed on the block-chain.",
  "order_status_tooltip_paid": "The order has been paid. We're working on dispatching the ordered currency.",
  "order_status_tooltip_paid_suspended": "The order has been paid.",
  "order_status_tooltip_dispatched": "We have dispatched the ordered currency to the target address/account. We are waiting for the confirmation on the block-chain/bank.",
  "order_status_tooltip_done": "The order is successfully processed.",
  "order_status_tooltip_canceled": "The order has been canceled.",
  "suspended_order_tooltip": "Order processing is suspended.",
  "order_detail_outgoing_payment_waiting_to_be_sent": "We are working to dispatch the ordered funds as soon as possible.",
  "exchange_limit_for_verification_level_2_text": "Your trading limit is not formally set. For large amounts, it will be assessed individually according to your <0>documented income</0>.",
  "your_limit_is": "Your limit is",
  "trading_limit": "Trading limit",
  "exchange_trading_limit_info": "The limit is automatically adjusted after we receive payment for your order. New unpaid orders do not affect the limit.",
  "you_have_no_suitable_wallet_among_recipients": "You have no wallet for {{currencyCode}} currency among your recipients.",
  "you_have_no_suitable_account_among_recipients": "You have no bank account for {{currencyCode}} currency among your recipients.",
  "rate_when_order_created": "Rate when order created",
  "order_status_proceed": "Proceed processing",
  "waiting_for_limit": "Waiting for limit",
  "button_more": "More ...",
  "exchange_form_expand_optional_fields": "Expand optional fields",
  "return_fiat_payment_info": "We have received your payment already. You have decided to get it back. But return payment has additional fee. You can get {{amount}} {{currency}} back to original bank account.",
  "cancel_order_and_return_payment": "Cancel order and return payment",
  "remaining_limit": "Remaining",
  "trading_limit_reached": "You have reached your trading limit for given period.",
  "attached_files": "Attached files",
  "there_are_no_files": "There are no uploaded files",
  "why_we_need_verification_info": "The anti money laundering law (AML) requires us to know our clients better. Therefore, it is necessary for you to tell us some information about yourself, which of course remains safely stored only with us.",
  "proceed_to_verification_action": "Proceed to verification, please",
  "verification_request_line2": "There are a few steps in our guide and then you can make full use of our wallet.",
  "verification_request_line1": "Now we need to get to know each other a little bit",
  "document_issued_by_government_info": "Please attach a government-issued document confirming the information provided in the previous step.",
  "secondary_document_info": "Please attach another document that confirms the information provided in the first step.",
  "selfie_with_id_info": "Please attach a photo of you holding the personal document  in your hand. The details on the document must be legible and your face must be visible.",
  "understood_button": "Understood",
  "no_prooved_incomes_info": "There is no proved income yet. You can still trade cryptocurrencies up to the base limit (10.000 EUR/month for verified clients). To increase the trading limit above 10 000 EUR/month, you will need to provide proof of income.",
  "verified": "Verified",
  "payment_instructions": "Payment instructions",
  "make_payment_to_one_of_our_accounts": "Make payment to one of our accounts",
  "payment_in": "Payment in",
  "iban_payment_instructions": "IBAN payment instructions",
  "new_price_is_being_loaded": "New price is being loaded...",
  "price_refresh_timer_tooltip": "We update the displayed price and rate for you every few seconds.",
  "send_to_address": "Send to address",
  "payment_specific_data": "Payment data",
  "bank_account_owner": "Bank account owner",
  "filter_all_currencies": "All currencies",
  "ticket_detail": "Ticket detail",
  "last_verification_step_warning": "This is the last step of the verification process. Once you have submitted it, you will no longer be able to change the information you have entered. Your profile will be verified by our back-office staff.",
  "exchange_form_limit_alert": "The order of this amount already exceeds your allowed trading limit {{limit}} {{code}}. You can still create the order, but it will not be processed until you verify yourself or provide proof of appropriate incomes.",
  "order_limit_alert": "Once you will pay for this order, you will exceed your allowed trading limit and the order will not be processed until you verify yourself or provide proof of appropriate incomes.",
  "remove_recipient_confirmation_question": "Do you really want to delete this recipient? You will not be able to undo this action.",
  "label_bank_account_iban": "IBAN",
  "validation_given_field_required": "Field '{{fieldName}}' is required",
  "inheritance": "Inheritance",
  "relationship_to_testator": "Relationship to testator",
  "inherited_from": "Inherited from (name)",
  "landing_how_to_get_started_button": "How to get started",
  "affiliate_partners_must_be_verified": "To become our affiliate partner, we first need to grab a few information about you.",
  "regular_income": "Regular income",
  "tax_declaration": "Tax declaration",
  "annual_report": "Annual report",
  "date_of_acceptance": "Date of acceptance",
  "document_confirming_acceptance_of_inheritance": "Document confirming acceptance of inheritance",
  "financial_closing_statement": "Financial closing statement",
  "decision_on_sharing_profit": "Decision on sharing profit",
  "sale_contract": "Sales contract",
  "real_estate_registry_extract": "Real estate registry extract",
  "typical_income_attachments": "Typical attachments",
  "sale_of_immovable_property": "Sale of immovable property",
  "address_of_property": "Address of property",
  "date_of_sale": "Date of sale",
  "sale_channel": "Sales channel / Way of sale",
  "paycheck": "Paycheck",
  "employment_contract": "Employment contract",
  "field_of_business": "Field of business",
  "company_id": "Company ID",
  "monetary_donation": "Monetary donation",
  "donor": "Donator",
  "donor_relationship_to_recipient": "Relationship of donor to recipient",
  "purpose_of_donation": "Purpose of donation",
  "source_of_donated_funds": "Source of donated funds",
  "email_subscribe_success": "You have successfully subscribed to our newsletter.",
  "landing_index_meta_tag_title": "Simplecoin - The largest cryptocurrency exchange in the Czech Republic",
  "landing_index_meta_tag_description": "Trusted since 2013",
  "landing_how_to_get_started_meta_tag_title": "Simplecoin - How to start buying cryptocurrencies",
  "landing_how_to_get_started_meta_tag_description": "A simple step-by-step guide for everyone",
  "landing_about_meta_tag_description": "We are cryptocurrency experts",
  "landing_about_meta_tag_title": "Simplecoin - About us",
  "landing_faq_meta_tag_description": "What do I need to buy cryptocurrencies? What do I gain by signing up?",
  "landing_faq_meta_tag_title": "Simplecoin - FAQ",
  "landing_support_meta_tag_title": "Simplecoin - Help",
  "landing_support_meta_tag_description": "Detailed instructions on how to set up a wallet, place an order or set up a DCA",
  "landing_tos_meta_tag_description": "Fair trading with cryptocurrencies",
  "landing_tos_meta_tag_title": "Simplecoin - Terms and conditions",
  "landing_privacy_meta_tag_title": "Simplecoin - Privacy Policy",
  "landing_privacy_meta_tag_description": "Your data is safe with us",
  "landing_affiliate_meta_tag_description": "Become a Simplecoin partner and earn commission",
  "landing_affiliate_meta_tag_title": "Simplecoin - Affiliate Program",
  "landing_limits_meta_tag_description": "Purchase options without registration and when you need to be verified",
  "landing_limits_meta_tag_title": "Simplecoin - Limits and Verification",
  "landing_contact_meta_tag_description": "Book a personal appointment at our client centre or take advantage of our client support 9am-9pm every day of the week.",
  "landing_contact_meta_tag_title": "Simplecoin - contact",
  "react_new_app_version_available_dialog_content": "A new version is available. Please refresh the page to ensure you're using the latest version.",
  "wallet_recipient_warning_1": "Anyone who knows the access secrets to the crypto-wallet (especially the recovery seed - 12-24 words used to recover the wallet) can freely use the funds in this wallet.",
  "wallet_recipient_warning_2": "If the wallet was created by someone else than myself, he/she probably has access to this wallet and can freely use the funds in this wallet.",
  "wallet_recipient_warning_3": "If I lose access to my wallet and do not have a backup (recovery seed), I lose access to all funds in my wallet.",
  "wallet_recipient_warning_4": "If I add an address of crypto-wallet that is not mine, I will not be able to directly use of the funds in that wallet. It is up to the third party to allow me to dispose of the funds.",
  "warning": "Warning",
  "ticket_attachments_info": "Attachments can be added to the ticket in the next step (after the ticket is created).",
  "one_off_income": "One-off income",
  "button_previous": "Previous",
  "years_of_service": "Years of service",
  "primary_or_secondary_activity": "Primary / secondary activity",
  "rental_contract": "Rental contract",
  "bank_statement_with_incoming_payment": "Bank statement with incoming payment",
  "pension_confirmation_letter": "Pension confirmation letter",
  "loan": "Loan",
  "loan_maturity_date": "Maturity date",
  "loan_effective_date": "Effective date",
  "lender": "Lender",
  "loan_agreement": "Loan agreement",
  "donation_agreement": "Donation agreement",
  "savings": "Savings",
  "bank_statement": "Bank statement",
  "sale_of_movable_property": "Sale of movable property",
  "sale_of_company_share": "Sale of company share",
  "investment_return": "Investment return",
  "investment_period": "Investment period",
  "crypto_mining": "Crypto mining",
  "mining_target_crypto_address": "Target crypto-address of mined coins",
  "verified_clients_limit_info": "Verified clients can trade up to EUR 10.000 per month without providing further proof of incomes.",
  "expected_trading_volume": "Expected trading volume",
  "expected_trading_volume_info": "Setting up expected trading volume is optional and is not a commitment. It will help us to better set your trading limits and thus speed up your trading.",
  "add_expected_trading_volume": "Add expected trading volume",
  "trading_volume_period": "Trading period",
  "landing_quarterly_volume": "90 day volume",
  "landing_quarterly_exchanges": "Exchanges in the last 90 days",
  "income_state_revoked": "revoked",
  "crypto_deposit_fee_info": "Speed of your crypto transaction depends on the transaction fee so it is important to use the correct transaction fee in order for transaction to be verified soon.",
  "deposit_on_account": "Deposit on account",
  "show": "show",
  "hide": "hide",
  "account_status_suspended": "suspended",
  "account_status_closed": "closed",
  "account_status_active": "active",
  "suspended_accounts": "Suspended accounts",
  "closed_accounts": "Closed accounts",
  "active_accounts": "Active accounts",
  "create_wallet_account_form_header": "Add a new account",
  "account_number": "Account number",
  "accounted_transactions": "Accounted transactions",
  "counterparty": "Counterparty",
  "all_financial_operations": "All",
  "accounting_balance": "Accounting balance",
  "pending_deposits": "Pending deposits",
  "pending_withdrawals": "Pending withdrawals",
  "pending_transactions": "Pending transactions",
  "blocked_amount_alert": "There is an amount {{amount}} {{currency}} blocked on this account. Please contact support for more information.",
  "available_balance_tooltip": "Available balance is the amount of money you currently have available for trading. The available balance does not include pending deposits but already takes into account amounts blocked, e.g. due to pending withdrawals.",
  "total_balance_tooltip": "The account total balance is the sum of all confirmed transactions in your account.",
  "deposit_requests_total_tooltip": "Pending deposits are incoming transactions to your account for which we are waiting for confirmation on the block-chain or have not been credited due to exceeding your trading limit.",
  "withdrawal_requests_total_tooltip": "Pending withdrawals are withdrawals awaiting confirmation on the block-chain.",
  "cancel_account": "Close account",
  "cancel_account_confirmation_question": "Are you sure you want to close this account? Closing the account is an irreversible operation.",
  "empty_account_required_to_close_it": "Only accounts with zero balance can be closed.",
  "account_closed_success": "The account has been closed.",
  "approved_incomes": "Approved incomes",
  "pending_incomes": "Pending incomes",
  "show_details": "show details",
  "hide_details": "hide details",
  "hide_approved_incomes": "Hide approved incomes",
  "show_approved_incomes": "Show approved incomes",
  "increase_trading_limit": "Increase trading limit",
  "save_as_draft": "Save as draft",
  "withdrawal_success": "Withdrawal has been successfully requested",
  "withdrawal_confirmation_transaction_fee_label": "Transaction fee: {{feeAmount}} {{feeCurrencyCode}}",
  "withdrawal_form_fee_in_amount_switch_label": "Withdrawal fee included",
  "withdrawal_recap_table_fee": "Fee amount",
  "withdrawal_recap_table_deducted_amount": "Withdrawal amount",
  "withdrawal_recap_table_net_withdraw_amount": "Recipient will get",
  "remove_income_confirmation": "Do you really want to delete this income?",
  "deposit_form_show_instructions": "Show deposit instructions",
  "income_state_readyForApproval": "awaits approval",
  "withdrawal_form_show_bank_fields_toggle": "Show modifiable fields",
  "end_income": "End income",
  "edit_income": "Edit income",
  "delete_income": "Delete income",
  "pending_transaction_detail": "Pending transaction detail",
  "account": "Account",
  "verification_wizard": "Verification wizard",
  "portfolio_volume": "Your portfolio volume",
  "profile_verification_info_message_for_business": "For business verification <0>read our guide</0>.",
  "unknown_source": "unknown source",
  "amount_netto": "Amount (netto)",
  "deposit_request_status_on_hold": "On Hold",
  "exchange_form_verified_client_discount_message": "Verified clients can save up to <0>{{nominalDiscount}} {{discountCurrency}}</0> per {{toCurrency}}. </1>",
  "sign_up_to_get_discount": "Sign up to get the discount",
  "exchange_form_verified_client_discount_message_generic": "Verified clients get a better price",
  "exchange_form_verified_client_discount_message_from_crypto": "Verified clients can immediately get <0>{{nominalDiscount}} {{discountCurrency}}</0> more per {{toCurrency}}.",
  "price_for_verified_clients": "Price for verified clients:",
  "contact_page_customer_center_reservation_info": "If you are interested in visiting our office, please <0>make an appintment</0>",
  "label_house_number": "House No",
  "react_file_upload_count_error": "the maximum number of uploaded files is {{maxFiles}}",
  "sorry_no_bank_for_given_currency": "We are sorry, but payments in {{currency}} are not possible at this moment.",
  "payment_instructions_delay_warning": "Due to the new provider of banking services, bank transfers may take longer than usual.\n<br/>\nTo ensure the original exchange rate from the order, you have the option to attach a PDF with payment confirmation from your bank. The price extension service is available daily from 9:00 to 21:00. Each request is assessed individually, and we will contact you as soon as possible.",
  "payment_confirmation_upload_info": "The duration of original exchange-rate is evaluated on an individual basis and depends on your payment history and order amount.",
  "attach_payment_confirmation": "Attach payment confirmation",
  "price_fixed_until": "The price is fixed until {{time}}",
  "payment_confirmation_attached": "Payment confirmation attached",
  "validation_incorrect_format_generic": "Invalid format",
  "exchange_fee_percentage_info": "Fee makes {{feePercentage}}% of the traded amount",
  "citizenship": "Citizenship",
  "second_citizenship": "Second citizenship",
  "second_citizenship_helper": "If you have two citizenships state both",
  "dashboard_intro_text_how_to_start": "Our wallet works similarly to internet banking. In order to start using Simplecoin Wallet, you need to deposit funds into virtual accounts you create in it. To get started, we recommend you set up one account each for the currencies you want to start trading. Later, of course, you can add more currency accounts.",
  "how_to_start_using_wallet": "How to start using Simplecoin wallet?",
  "create_accounts": "Create accounts",
  "default_account_label": "{{currencyCode}} default account",
  "default_account_description": "This account was created automatically for you by the initial wizard.",
  "about_virtual_account_info": "A virtual account is where you keep your funds in your Simplecoin wallet. If you want to hold funds in your chosen currency, you must create at least one account for that currency. Setting up and maintaining an account is free. Trading is done by transferring funds between accounts, \nwhich may be in different currencies.",
  "preferred_currencies_question": "Which currencies will you most often trade in?",
  "crypto": "Crypto",
  "fiat": "Fiat",
  "create_accounts_for_selected_currencies": "Create accounts for selected currencies",
  "accounts_successfully_created": "Accounts has been successfully created! We have created {{accountsCount}} accounts for you.",
  "create_your_first_accounts": "Create your first accounts",
  "wallet_logo": "wallet",
  "just_one_citizenship": "just one citizenship",
  "information_about_verification_status_change": "We will inform you about the change of verification status to e-mail {{email}}.",
  "amount_too_low": "Amount too low",
  "footer_link_legal_documents": "Legal documents",
  "log_into_wallet": "Wallet",
  "log_into_exchange": "Exchange",
  "log_into_exchange_caption": "One-off purchases to your wallet",
  "log_into_wallet_caption": "The easiest cryptocurrency trading",
  "payment_request": "Payment request",
  "payment_hash": "Payment hash",
  "memo": "Memo",
  "scan_invoice_qr": "Scan invoice QR",
  "lightning_payment": "Lightning payment",
  "scan_qr": "Scan QR",
  "pay": "Pay"
}